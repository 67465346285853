import React, { useRef, useState, useEffect } from "react";
import { filterEvents, shareUrl } from "../helper/Data";
import VenueCard from "../components/venueCard";
import CategoryMap from "../components/categoryMap";
import pageTransition from "../components/pageTransition";

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link, useLocation, useNavigate } from 'react-router-dom';
import StartSettings from "../components/startSettings";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";

interface ConcertProps {
    categoriesLoaded: boolean;
    eventsLoaded: boolean;
}

interface Category {
    id: number;
    parent_id: number;
    slug: string;
    name_de: string;
    description_de: string;
    name_en: string;
    description_en: string;
    search_str: string;
    venue_count: number;
}

const Concert: React.FC<ConcertProps> = ({ categoriesLoaded, eventsLoaded }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Use `useLocation` to extract category and parent
    const location = useLocation();
    const pathname = location.pathname.endsWith('/')
    ? location.pathname.slice(0, -1)
    : location.pathname;
    const segments = pathname.substring(1).split('/'); 
    const categorySlug = segments.pop();

    const { trackPageView } = useMatomo();

    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));
    const [language] = useState(localStorage.getItem('i18nextLng') || 'de');

    // 1. venues of category
    const [events, setEvents] = useState<any[]>([])
    const [eventsByDay, setEventsByDay] = useState<any[]>([])

    // 2. mapped venues for the marker
    const [eventsMarker, setEventsMarker] = useState([]);

    const [category, setCategory] = useState<Category | null>(null);

    // Slider
    const sliderRef = useRef<Slider>(null);

    // Callback function to handle slide change
    const handleAfterChange = (currentIndex: number) => {
        console.log('Slide changed to:', currentIndex);
        setCurrentSlide(currentIndex);
    };

    // Callback function before the slide changes
    const handleBeforeChange = (oldIndex: number, newIndex: number) => {
        console.log('Slide is about to change from:', oldIndex, 'to:', newIndex);
        if (newIndex === 1) {
            setSliderSettings((prevSettings) => ({
                ...prevSettings,
                swipe: false,
            }));

            // @ts-ignore
            document.getElementById('header-category').className = 'transparent ion-padding';
        } else {
            setSliderSettings((prevSettings) => ({
                ...prevSettings,
                swipe: true,
            }));

            // @ts-ignore
            document.getElementById('header-category').className = 'ion-padding';
        }
    };

    const [sliderSettings, setSliderSettings] = useState({
        dots: false,           // Keine Dots anzeigen
        infinite: false,       // Keine Endlosschleife
        speed: 500,
        slidesToShow: 1,       // Nur eine Slide anzeigen
        slidesToScroll: 1,
        autoplay: false,       // Kein automatisches Weiterblättern
        arrows: false,           // Pfeile für die Navigation (optional)
        afterChange: handleAfterChange, // Detect slide change after it happens
        beforeChange: handleBeforeChange,
        swipe: true,
    });

    const changeSlide = (index: number) => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(index);
        }
    }

    const [currentSlide, setCurrentSlide] = useState(0);

    //map marker
    const [selectedMarkerID, setSelectedMarkerID] = useState<number>(-1);

    const handleMarkerSelected = (index: number | null) => {
        if (index !== null) {
            setSelectedMarkerID(index);
            //console.log(index);
        }
    };


    useEffect(() => {
        if (!categoriesLoaded || !eventsLoaded) return;
        trackPageView({
            href: window.location.href, // URL der aktuellen Seite
          });


        // Get Category
        const categories: any = localStorage.getItem('categories');
        if (categories) {
            let currentCategoryId = -1;
            // @ts-ignore
            JSON.parse(categories).forEach((item: any) => {
                if (item.slug === categorySlug) {
                    setCategory(item);
                    currentCategoryId = item.id;
                }
            })

            if (currentCategoryId < 0) {
                navigate('/notfound');
            }


            // Get events
            // @ts-ignore
            let tempEvents: any = JSON.parse(localStorage.getItem('events'));
            tempEvents = filterEvents(tempEvents, [currentCategoryId]);
            setEvents(tempEvents);

            type Event = {
                id: number;
                type: string;
                venue_title: string;
                venue_slug: string;
                restaurant__gps_lon: string;
                restaurant__gps_lat: string;
                category: number[];
                description_1: string;
                description_2: string;
                dow: string;
                dow_till: string;
                date: string | null;
              };
            
            // @ts-ignore           
            function parseEventDate(dateStr: string): Date {
                const parts = dateStr.split('.');
                // parts[0] is the day, parts[1] is the month, parts[2] is the year
                // Subtract 1 from the month to account for the 0-indexed months in JavaScript
                return new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
            }
            
            // @ts-ignore    
            function organizeEventsByDate(events: Event[], startDate: Date): Event[][] {
                // Initialize an array of 7 arrays for the next 7 days
                const eventsByDate: Event[][] = [[], [], [], [], [], [], []];
              
                // Calculate the end date (6 days into the future)
                const endDate = new Date(startDate);
                endDate.setDate(startDate.getDate() + 6);

                // Get the day of the week for the startDate (1 = Monday, 2 = Tuesday, etc.)
                const startDayOfWeek = (startDate.getDay() + 6) % 7; // Adjusting so 0 = Monday
              
                events.forEach(event => {
                  if (event.type === 'special' && event.date) {
                    const eventDate = parseEventDate(event.date);
                    console.log("Special")
                    console.log(eventDate);
                    if (eventDate >= startDate && eventDate <= endDate) {
                      const dayIndex = Math.floor((eventDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;
                      eventsByDate[dayIndex].push(event);
                    }
                  } else if (event.type === 'weekly') {
                    let startDay = parseInt(event.dow) - 1; // Adjusting for array index (0-6)
                    let endDay = event.dow_till ? parseInt(event.dow_till) - 1 : startDay;

                    // Adjust the days based on the startDayOfWeek
                    startDay = (startDay + 7 - startDayOfWeek) % 7;
                    endDay = (endDay + 7 - startDayOfWeek) % 7;
              
                    for (let day = startDay; day <= endDay; day++) {
                      // Calculate the date for the current day of the week
                      const currentDate = new Date(startDate);
                      currentDate.setDate(startDate.getDate() + day);
              
                      if (currentDate >= startDate && currentDate <= endDate) {
                        const dayIndex = day;
                        eventsByDate[dayIndex].push(event);
                      }
                    }
                  }
                });
              
                return eventsByDate;
              }
              
              // Usage
              const today = new Date(); // Replace with the current date
              const eventsOrganizedByDate = organizeEventsByDate(tempEvents, today);
              setEventsByDay(eventsOrganizedByDate);
              console.log("organized events");
              console.log(eventsOrganizedByDate);

            // @ts-ignore
            const newmarkers = tempEvents.map(({ id, venue_title, restaurant__gps_lat, restaurant__gps_lon }) => ({
                id,
                title: venue_title, // Changed field name from venue_title to title
                isOpen: true, // Set isOpen to always be true
                restaurant__gps_lat,
                restaurant__gps_lon,
            }));

            // @ts-ignore   
            newmarkers.sort((a, b) => b.restaurant__gps_lat - a.restaurant__gps_lat);
            // @ts-ignore
            setEventsMarker(newmarkers);

        }
    }, [location, categoriesLoaded, eventsLoaded]);


    function getEventById(id: number, events: any) {
        const foundEvent = events.find((event: { id: number; }) => event.id === id);
        return foundEvent || null;
    }

    ////// Return Statement ///////   
    
    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
        categoriesLoaded && eventsLoaded ? (
            <ion-page class="sg-body">
                <MetaHelmet 
                    title= {language === 'de' ? category?.name_de : category?.name_en}
                    desc= {language === 'de' ? category?.description_de : category?.description_en}
                />
                <ion-header class="ion-padding" id="header-category" mode="ios">
                    <div className="header-container">
                        <div className="header-container__title">
                            <h1 className="start-title left">
                                {language === 'de' ? category?.name_de : category?.name_en}
                            </h1>
                            <span className="share-icon info" id="open-description-modal"></span>
                        </div>
                        <span onClick={() => shareUrl()} className="share-icon"></span>
                    </div>
                    <div className="filter-map-container">
                        <ion-segment class="segment" value={currentSlide}>
                            <ion-segment-button class="segment-button" onClick={() => changeSlide(0)} value={0}>
                                <label>{t('list')}</label>
                            </ion-segment-button>
                            <ion-segment-button class="segment-button" onClick={() => changeSlide(1)} value={1}>
                                <label>{t('map')}</label>
                            </ion-segment-button>
                        </ion-segment>
                    </div>
                </ion-header>
                    <div className="slider-container">
                        <Slider ref={sliderRef} {...sliderSettings}>
                            <div className="slide">
                                <div className="venue-list ion-padding event">
                                    {eventsByDay.map((dailyEvents, index) => {
                                    // Calculate the date for the current index
                                    const currentDate = new Date();
                                    currentDate.setDate(currentDate.getDate() + index);
                                    const dateString = currentDate.toLocaleDateString(language+'-de', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
                                    if (dailyEvents.length > 0) {
                                    return (
                                        <div key={index}>
                                        <h2>{dateString}</h2> {/* Displaying the weekday and date */}
                                        {dailyEvents.map((event: { id: React.Key | null | undefined; venue_slug: string; venue_title: string; description_1: string; description_2: string; }) => (
                                                <VenueCard
                                                key={event.id}
                                                slug={event.venue_slug}
                                                name={event.venue_title}
                                                status={event.description_1}
                                                additionalInformation={event.description_2}
                                                />
                                            ))}
                                        </div>
                                    );
                                    }
                                    return null; // Don't render anything if there are no events for the day
                                    })}
                                </div>
                            </div>
                            <div className="slide">
                                <CategoryMap markers={eventsMarker} onMarkerSelected={handleMarkerSelected}></CategoryMap>
                                {(selectedMarkerID !== -1) &&
                                <Link className="venue-list__item map" to={`/betriebe/${getEventById(selectedMarkerID, events)?.venue_slug}`}>
                                    <h3 className="venue-list__item-name">{getEventById(selectedMarkerID, events)?.venue_title}</h3>
                                    <p className="venue-list__item-hours">{getEventById(selectedMarkerID, events)?.description_1}</p>
                                    <p className="venue-list__item-hours">{getEventById(selectedMarkerID, events)?.description_2}</p>
                                </Link>
                                }
                            </div>
                        </Slider>
                    </div>
            </ion-page>
            ) : (
                <ion-page class="sg-body">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>Loading....</ion-title>
                        </ion-toolbar>
                    </ion-header>
                        <div className="sg-spinner"></div>
                </ion-page>
            )
        )
    )
}

export default pageTransition(Concert);