import React, { useState, useEffect } from "react";
import pageTransition from "../components/pageTransition";
import CategoryCard from "../components/categoryCard";
import StartSettings from "../components/startSettings";
import SearchBar from "../components/searchBar";
import { useTranslation } from "react-i18next";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useLocation } from 'react-router-dom';
import MetaHelmet from '../components/metaHelmet';

const Home: React.FC = () => {
    const { t } = useTranslation();
    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));

    const location = useLocation();
    const { trackPageView } = useMatomo();
  
    useEffect(() => {
      trackPageView({
        href: window.location.href, // URL der aktuellen Seite
      });
    }, [location]);

    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
            <ion-page class="sg-body home">
                <MetaHelmet 
                    title="" 
                />
                <div
                    className="logo-container"
                    style={{ backgroundImage: `url("/icons/smartGastro-rund-white.svg")` }}
                >
                    <h1
                        className="start-title">{t('start_title')}</h1>
                </div>
                <div className="searchbar-container">
                    <SearchBar initialtext='' />
                </div>
                <div className="grid-container home">
                    <CategoryCard currentUrl={""} name={t('essen')} slug="essen" isParent={true} />
                    <CategoryCard currentUrl={""} name={t('trinken')} slug="trinken" isParent={true} />
                    <CategoryCard currentUrl={""} name={t('aktivitaeten')} slug="aktivitaeten" isParent={true} />
                    <CategoryCard currentUrl={""} name={t('events')} slug="events" isParent={true} />
                </div>
            </ion-page>
        )
    );
};

export default pageTransition(Home);
