import React, { useState } from 'react';
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { getParentSlugOfId } from '../helper/Data';

interface Category {
  id: number;
  parent_id: number;
  slug: string;
  name_de: string;
  description_de: string;
  name_en: string;
  description_en: string;
  search_str: string;
}

interface Venue {
  id: number;
  title: string;
  slug: string;
  category: number[];
  parent_category: number[];
  // ... other venue properties
}

export default function SearchBar(props: { initialtext: string }) {
  const { initialtext } = props;
  const [autocompleteCategories, setAutocompleteCategories] = useState<Category[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  const [autocompleteVenues, setAutocompleteVenues] = useState<Venue[]>([]);
  const [venues, setVenues] = useState<Venue[]>([]);

  const [searchQ, setsearchQ] = useState<string>('');

  const navigate = useNavigate();

  React.useEffect(() => {
    setsearchQ(initialtext);
    setAutocompleteCategories([]);
    setAutocompleteVenues([]);

    let tempCategories = localStorage.getItem('categories');
    let tempVenues = localStorage.getItem('venues');
    let cats = JSON.parse(tempCategories || '[]') as Category[];
    let ven = JSON.parse(tempVenues || '[]') as Venue[];

    const filteredcats = cats.filter((category) => [83, 84, 85, 86].includes(category.parent_id));
    setCategories(filteredcats);
    setVenues(ven);
  }, []);

  const onSearch = (searchQuery: string) => {
    if (searchQuery.length >= 1) {
      const filteredCategories = filterCategories(searchQuery);
      setAutocompleteCategories(filteredCategories);
      const filteredVenues = filterVenues(searchQuery);
      setAutocompleteVenues(filteredVenues);
    } else {
      setAutocompleteCategories([]);
      setAutocompleteVenues([]);
    }
    setsearchQ(searchQuery);
  };

  const filterCategories = (searchQuery: string): Category[] => {
    const query = searchQuery.toLowerCase();
    const matchingCategories = categories.filter((category) => {
      const categoryNameWords = category.search_str.toLowerCase().split(/[\s;,]+/); // Split category name into words
      return categoryNameWords.some((word) => word.startsWith(query));
    });
    return matchingCategories.slice(0, 3); // Return a maximum of 3 suggestions
  };

  const filterVenues = (searchQuery: string): Venue[] => {
    const query = searchQuery.toLowerCase();
    const matchingVenues = venues.filter((venue) => {
      const venueNameWords = venue.title.toLowerCase().split(' '); // Split venue name into words
      return venueNameWords.some((word) => word.startsWith(query));
    });
    return matchingVenues.slice(0, 3); // Return a maximum of 3 suggestions
  };

  const showResultsList = autocompleteCategories.length > 0 || autocompleteVenues.length > 0;

  const keypress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      console.log('Enter clicked with:' + searchQ);
      navigate(`/suche?q=${searchQ}`);
      setAutocompleteCategories([]);
      setAutocompleteVenues([]);
    }
  };

  return (
    <div className="searchbar">
      <ion-searchbar
        value={searchQ}
        mode="ios"
        placeholder={t('search')}
        onKeyUp={keypress}
        onInput={(e: CustomEvent) => {
          const searchQuery = (e.target as HTMLInputElement).value;
          onSearch(searchQuery);
        }}
      ></ion-searchbar>
      {showResultsList && (
        <ion-list class="search-list">
          {autocompleteCategories.map((category) => (
            <ion-item lines="none" key={category.id}>
              <ion-thumbnail slot="start" className="autocomplete-icon">
                <img src={`/icons/${category.slug}.svg`} />
              </ion-thumbnail>
              <ion-label>

                <Link to={`/${getParentSlugOfId(category.parent_id)}/${category.slug}`}>
                  {category.name_de}
                </Link>
              </ion-label>
            </ion-item>
          ))}
          {autocompleteVenues.map((venue) => (
            <ion-item lines="none" key={venue.id}>
              <ion-label>
                <Link to={`/betriebe/${venue.slug}`}>{venue.title}</Link>
              </ion-label>
            </ion-item>
          ))}
        </ion-list>
      )}
    </div>
  );
}
