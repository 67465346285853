import React, { useState, useEffect } from "react";
import SearchBar from "../components/searchBar";
import { getParentSlugOfId } from "../helper/Data";
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import StartSettings from "../components/startSettings";
import pageTransition from "../components/pageTransition";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";


interface Category {
    id: number;
    parent_id: number;
    slug: string;
    name_de: string;
    description_de: string;
    name_en: string;
    description_en: string;
    search_str: string;
}

interface Venue {
    id: number;
    title: string;
    slug: string;
    category: number[];
    parent_category: number[];
    // ... other venue properties
}


const SearchResults: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const { trackPageView } = useMatomo();

    // Extract search query from the URL
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get('q') || '';

    // Load settings
    const [settingsSet, setSettingsSet] = useState<boolean>(() => {
        if (typeof window !== 'undefined') {
            // Client
            const settingsSet = localStorage.getItem('settings-set');
            return settingsSet === 'yes';
        } else {
            // Server
            return false;
        }
    });
    const [language, setLanguage] = useState('de');

    const [autocompleteCategories, setAutocompleteCategories] = useState<Category[]>([]);
    const [autocompleteVenues, setAutocompleteVenues] = useState<Venue[]>([]);

    useEffect(() => {
        trackPageView({
            href: window.location.href, // URL der aktuellen Seite
          });

        // Get language
        const lang = localStorage.getItem('i18nextLng');
        if (lang) {
            setLanguage(lang);
        } else {
            setLanguage('de');
        }

        if (searchQuery !== null) {
            let tempCategories = localStorage.getItem('categories');
            let tempVenues = localStorage.getItem('venues');
            let cats = JSON.parse(tempCategories || '[]') as Category[];
            let ven = JSON.parse(tempVenues || '[]') as Venue[];

            const filteredcats = cats.filter(category => [83, 84, 85, 86].includes(category.parent_id));

            const filteredCategories = filterCategories(searchQuery, filteredcats);
            setAutocompleteCategories(filteredCategories);

            const filteredVenues = filterVenues(searchQuery, ven);
            setAutocompleteVenues(filteredVenues);
        }
    }, [location]);

    const filterCategories = (searchQuery: string, categoriesToFilter: Category[]): Category[] => {
        const query = searchQuery.toLowerCase();
        const matchingCategories = categoriesToFilter.filter((category) => {
            const categoryNameWords = category.search_str.toLowerCase().split(/[\s;,]+/); // Split category name into words
            return categoryNameWords.some(word => word.startsWith(query));
        });
        return matchingCategories
    };

    const filterVenues = (searchQuery: string, venuesToFilter: Venue[]): Venue[] => {
        const query = searchQuery.toLowerCase();
        const matchingVenues = venuesToFilter.filter((venue) => {
            const venueNameWords = venue.title.toLowerCase().split(' '); // Split venue name into words
            return venueNameWords.some(word => word.startsWith(query));
        });
        return matchingVenues
    };

    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
            <ion-page class="sg-body">
                <MetaHelmet 
                title={t('searchResults')}
                />
                <ion-header class="ion-padding" mode="ios">
                    <div className="header-container">
                        <div className="header-container__title">
                            <h1
                                className="start-title left">
                                {t('searchResults')}
                            </h1>
                        </div>
                    </div>

                    {(searchQuery !== null) &&
                        <SearchBar initialtext={searchQuery} />}
                    {(searchQuery == null) &&
                        <SearchBar initialtext='' />}
                </ion-header>
                <div className="ion-padding search-results">
                    <ion-list lines="full">
                        {(autocompleteCategories.length > 0) &&
                            <h2 className="search-results-heading">{t('categories')}</h2>}
                        {autocompleteCategories.map(function (category) {
                            return (
                                <Link
                                    className="search-results-item"
                                    key={category.id}
                                    to={`/${getParentSlugOfId(category.parent_id)}/${category.slug}`}>
                                    {(language === 'de') &&
                                        <span
                                            className="search-results-item-category"
                                            style={{ backgroundImage: `url("/icons/${category.slug}.svg` }}
                                        >{category.name_de}</span>}
                                    {(language === 'en') &&
                                        <span
                                            className="search-results-item-category"
                                            style={{ backgroundImage: `url("/icons/${category.slug}.svg` }}
                                        >{category.name_en}</span>}
                                </Link>
                            );
                        })}
                    </ion-list>
                    <ion-list lines="full" style={{ marginTop: '10px' }}>
                        {(autocompleteVenues.length > 0) &&
                            <h2 className="search-results-heading">{t('venues')}</h2>}
                        {autocompleteVenues.map(function (venue) {
                            return (
                                <Link className="search-results-item"
                                    to={`/betriebe/${venue.slug}`}
                                    key={venue.id}>
                                    {venue.title}
                                </Link>
                            );
                        })}
                    </ion-list>
                </div>
            </ion-page>
        )
    )
}

export default pageTransition(SearchResults);