import React, { useRef, useState, useEffect } from "react";
import { filterVenues, shareUrl } from "../helper/Data";
import { getVenueOpeningHours, openValueToTimeString } from "../helper/Filter";
import VenueCard from "../components/venueCard";
import CategoryMap from "../components/categoryMap";
import pageTransition from "../components/pageTransition";

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link, useLocation, useNavigate } from 'react-router-dom';
import StartSettings from "../components/startSettings";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";

interface CategoryProps {
    categoriesLoaded: boolean;
    venuesLoaded: boolean;  // Neue Prop für den venuesLoaded-Status
}

interface Category {
    id: number;
    parent_id: number;
    slug: string;
    name_de: string;
    description_de: string;
    name_en: string;
    description_en: string;
    search_str: string;
    venue_count: number;
}

const Category: React.FC<CategoryProps> = ({ categoriesLoaded, venuesLoaded }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Use `useLocation` to extract category and parent
    const location = useLocation();
    const { trackPageView } = useMatomo();
    const pathname = location.pathname.endsWith('/')
    ? location.pathname.slice(0, -1)
    : location.pathname;
    const segments = pathname.substring(1).split('/'); 
    const categoryName = segments.pop();
    const parentCategoryName = segments.length > 0 ? segments.pop() : "";   

    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));
    const [language] = useState(localStorage.getItem('i18nextLng') || 'de');
    const [sortingPreference, setSortingPreference] = useState(localStorage.getItem('sorting') || 'popularity');

    // 1. venues of category
    const [venues, setVenues] = useState<any[]>([])
    // 2. venues filtered by category
    const [filteredVenues, setFilteredVenues] = useState<any[]>([])

    const filteredVenuesRef = useRef(filteredVenues);

    useEffect(() => {
    filteredVenuesRef.current = filteredVenues;
    }, [filteredVenues]);

    // 3. venues filterd by time (all venues will be in OPEN when no time filter is set)
    const [venuesOpen, setVenuesOpen] = useState([]);
    const [venuesClosed, setVenuesClosed] = useState([]);
    // 4. mapped venues for the marker
    const [venuesMarker, setVenuesMarker] = useState([]);

    const [category, setCategory] = useState<Category | null>(null);

    // category filter
    const [filterCategories, setFilterCategories] = useState([]);
    const [hasGastgarten, setHasGastgarten] = useState(false);
    const [hasMittagsmenu, setHasMittagsmenu] = useState(false);
    const [hasParkplatz, setHasParkplatz] = useState(false);
    const [hasSchnell, setHasSchnell] = useState(false);

    // datefilter
    const [datetimeValue, setDatetimeValue] = useState(new Date())
    const [datetimeFilterOn, setDatetimeFilterOn] = useState(false);


    // Slider
    const sliderRef = useRef<Slider>(null);

    // Callback function to handle slide change
    const handleAfterChange = (currentIndex: number) => {
        console.log('Slide changed to:', currentIndex);
        setCurrentSlide(currentIndex);
    };

    // Callback function before the slide changes
    const handleBeforeChange = (oldIndex: number, newIndex: number) => {
        console.log('Slide is about to change from:', oldIndex, 'to:', newIndex);
        if (newIndex === 1) {
            setSliderSettings((prevSettings) => ({
                ...prevSettings,
                swipe: false,
            }));

            // @ts-ignore
            document.getElementById('header-category').className = 'transparent ion-padding';
        } else {
            setSliderSettings((prevSettings) => ({
                ...prevSettings,
                swipe: true,
            }));

            // @ts-ignore
            document.getElementById('header-category').className = 'ion-padding';
        }
    };

    const [sliderSettings, setSliderSettings] = useState({
        dots: false,           // Keine Dots anzeigen
        infinite: false,       // Keine Endlosschleife
        speed: 500,
        slidesToShow: 1,       // Nur eine Slide anzeigen
        slidesToScroll: 1,
        autoplay: false,       // Kein automatisches Weiterblättern
        arrows: false,           // Pfeile für die Navigation (optional)
        afterChange: handleAfterChange, // Detect slide change after it happens
        beforeChange: handleBeforeChange,
        swipe: true,
    });

    const changeSlide = (index: number) => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(index);
        }
    }

    const [currentSlide, setCurrentSlide] = useState(0);

    //map marker
    const [selectedMarkerID, setSelectedMarkerID] = useState<number>(-1);

    const handleMarkerSelected = (index: number | null) => {
        if (index !== null) {
            setSelectedMarkerID(index);
            console.log(index);
        }
    };

    // modal init
    const sortingModal = useRef<HTMLIonModalElement>(null);

    useEffect(() => {
        if (!categoriesLoaded || !venuesLoaded) return;
        trackPageView({
            href: window.location.href, // URL der aktuellen Seite
          });

        // Get Categories
        const categories: any = localStorage.getItem('categories');
        if (categories) {
            console.log(categoryName);
            let categorySlug = categoryName;

            if (categorySlug === "alle") {
                categorySlug = parentCategoryName;
            }

            let currentCategoryId = -1;
            // @ts-ignore
            JSON.parse(categories).forEach((item: any) => {
                if (item.slug === categorySlug) {
                    console.log('Item:', item);
                    setCategory(item);
                    console.log('Category after set:', category);

                    currentCategoryId = item.id;
                    // @ts-ignore
                    setFilterCategories([currentCategoryId]);
                }
            })

            if (currentCategoryId < 0) {
                navigate('/notfound');
            }


            // Get Venues
            // @ts-ignore
            let tempVenues: any = JSON.parse(localStorage.getItem('venues'));
            tempVenues = filterVenues(tempVenues, [currentCategoryId]);
            setVenues(tempVenues);
            tempVenues = filterVenues(tempVenues, filterCategories);
            setFilteredVenues(tempVenues);


            console.log(category)

            // @ts-ignore
            getOpenVenues(tempVenues, new Date(), datetimeFilterOn, sortingPreference);

            console.log(venuesOpen);
            console.log(venuesClosed);

        }
    }, [location, categoriesLoaded, venuesLoaded]);



    ////// --- Category Filter Functions ----- ///////

    const addFilter = (filterToToggle: number) => {
        let filterArray: number[] = [...filterCategories];

        // gastgarten
        if (filterToToggle === 9) {
            setHasGastgarten((hasGastgarten) => !hasGastgarten);
        } else if (filterToToggle === 22) {
            setHasMittagsmenu((hasMittagsmenu) => !hasMittagsmenu);
        } else if (filterToToggle === 129) {
            setHasParkplatz((hasParkplatz) => !hasParkplatz);
        } else if (filterToToggle === 138) {
            setHasSchnell((hasSchnell) => !hasSchnell);
        }

        if (filterArray.includes(filterToToggle)) {
            const index = filterArray.indexOf(filterToToggle);
            console.log(index)
            if (index > -1) { // only splice array when item is found
                filterArray.splice(index, 1); // 2nd parameter means remove one item only
            }
        } else {
            filterArray.push(filterToToggle);
        }

        // @ts-ignore
        setFilterCategories(filterArray);
        let tempVenues = filterVenues(venues, filterArray);
        setFilteredVenues(tempVenues);

        // @ts-ignore
        getOpenVenues(tempVenues, datetimeValue, datetimeFilterOn, sortingPreference);
    }

    ////// --- Time Filter Functions ----- ///////

    useEffect(() => {
      const datetimeElement = document.getElementById('datetime-wheel');
  
        const handleDateChange = (event: Event) => {

            const customEvent = event as CustomEvent;
            const dtValue = new Date(customEvent.detail.value);
            console.log('timefilter set with '+dtValue);
            setDatetimeValue(dtValue);
            setDatetimeFilterOn(true);
            // @ts-ignore
            getOpenVenues(filteredVenuesRef.current, dtValue, true, sortingPreference);
        };

        const handleCancel = () => {
            console.log('timefilter cancel');
            const dtValue = new Date();
            setDatetimeValue(dtValue);
            setDatetimeFilterOn(false);
            // @ts-ignore
            getOpenVenues(filteredVenuesRef.current, dtValue, false, sortingPreference);
        };
  
      if (datetimeElement) {
        // Using the string type for the event name to avoid TypeScript errors
        datetimeElement.addEventListener('ionChange', handleDateChange as EventListener);
        datetimeElement.addEventListener('ionCancel', handleCancel as EventListener);
      }
  
      // Cleanup event listener on component unmount
      return () => {
        if (datetimeElement) {
          datetimeElement.removeEventListener('ionChange', handleDateChange as EventListener);
          datetimeElement.removeEventListener('ionCancel', handleCancel as EventListener);
        }
      };
    }, [document]);

    function getOpenVenues(venues: [], date: Date, timeFilterOn: boolean, sortingPref: string) {
        let newVenues = venues.map((item: any) => ({
            ...item
        }));

        let openVenues: any[] = [];
        let closedVenues: any[] = [];


        let dow = date.getDay();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const floatTime = hours + minutes / 60
        if (dow == 0) {
            dow = 7
        } //Sonntag auf europäischen Wert
        // TODO: Feiertag Funktion hinzufügen (check ob gewählter Tag ein Feiertag ist, wenn ja dow = 8)


        for (let i = 0; i < newVenues.length; i++) {

            // Checks if the venues is open in the selected time & dow and returns 0 if venue is closed on the day or -[open time] if the venue opens on the day or + [open till time] if venue is open
            let openValue = getVenueOpeningHours(newVenues[i].restaurant__opening_hours, floatTime, dow);

            newVenues[i].isOpen = false;
            if (openValue > 0) {
                // TODO: schließt demnächst check einbauen
                newVenues[i].status = t('todayUntil') + openValueToTimeString(openValue);
                newVenues[i].isOpen = true;
                openVenues.push(newVenues[i]);
            } else if (openValue < 0) {
                // TODO: Zuastz if Bedignung einfügen wenn nur gewisse Stunden vorher angezeigt
                // e.g. && (openValue - time) < 4
                newVenues[i].status = t('closed') + t('opensAt') + openValueToTimeString((-openValue));
                if (timeFilterOn) {
                    closedVenues.push(newVenues[i]);
                } else {
                    openVenues.push(newVenues[i]);
                }
            } else {
                newVenues[i].status = t('closed');
                if (timeFilterOn) {
                    closedVenues.push(newVenues[i]);
                } else {
                    openVenues.push(newVenues[i]);
                }
            }
        }

        // @ts-ignore
        setVenuesOpen(openVenues);
        // @ts-ignore
        setVenuesClosed(closedVenues);
        // @ts-ignore
        performSorting(openVenues, closedVenues, sortingPref);


        const newmarkers = newVenues.map(({ id, title, isOpen, restaurant__gps_lat, restaurant__gps_lon }) => ({
            id,
            title,
            isOpen,
            restaurant__gps_lat,
            restaurant__gps_lon,
        }));

        newmarkers.sort((a, b) => b.restaurant__gps_lat - a.restaurant__gps_lat);
        // @ts-ignore
        setVenuesMarker(newmarkers);

    }

    ////// --- Sorting Functions ----- ///////

    const changeSortingPreference = (sortingpref: string) => {
        console.log('Sorting changed to ' + sortingpref)

        // localStorage.setItem('sorting', ev.detail.value);
        // shall not be changed everytime, shall be done in settings -> TODO discuss in design session

        setSortingPreference(sortingpref);
        // @ts-ignore
        performSorting(venuesOpen, venuesClosed, sortingpref);

        // Close the modal after handling the preference change
        if (sortingModal.current) {
            sortingModal.current.dismiss();
        }
    }

    const performSorting = (venues_open: [], venues_closed: [], sortingpref: string) => {
        if (sortingpref == 'distance') {
            getUserPosition()
                .then(pos => {
                    console.log("User Position: ", pos);
                    // @ts-ignore
                    sortVenues(venues_open, venues_closed, 'distance', pos.lat, pos.lon)
                })
                .catch(error => {
                    alert(error);
                    localStorage.setItem('sorting', 'popularity');
                    setSortingPreference('popularity');
                });
        } else {
            sortVenues(venues_open, venues_closed, 'popularity', 0, 0)
        }
    }

    const getUserPosition = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lon: position.coords.longitude
                        };
                        resolve(pos);
                    },
                    () => {
                        reject("Leider haben wir aktuell keinen Zugriff auf deinen Standort. Bitte überprüfe deine Einstellungen!");
                    }
                );
            } else {
                reject("Leider haben wir aktuell keinen Zugriff auf deinen Standort. Bitte überprüfe deine Einstellungen!");
            }
        });
    }

    function sortVenues(venues_open: [], venues_closed: [], sorting_pref: string, user_lat: number, user_lon: number) {

        if (sorting_pref == 'popularity') {
            console.log('sort popularity')
            if (venues_open.length) {
                console.log('sort venues open')
                let newOpenVenues = venues_open.map((item: any) => ({
                    ...item
                }));

                newOpenVenues.sort((a, b) => b.restaurant__views - a.restaurant__views);

                // @ts-ignore
                setVenuesOpen(newOpenVenues);
            }
            if (venues_closed.length) {
                console.log('sort venues closed')
                let newClosedVenues = venues_closed.map((item: any) => ({
                    ...item
                }));

                newClosedVenues.sort((a, b) => b.restaurant__views - a.restaurant__views);

                // @ts-ignore
                setVenuesClosed(newClosedVenues);
            }
        }

        if (sorting_pref == 'distance') {
            console.log('sort distance')
            if (venues_open.length) {
                let newOpenVenues = venues_open.map((item: any) => ({
                    ...item
                }));

                let distance = 0;
                let distance_text = '';

                for (let i = 0; i < newOpenVenues.length; i++) {

                    distance = getDistance(parseFloat(newOpenVenues[i].restaurant__gps_lat), parseFloat(newOpenVenues[i].restaurant__gps_lon), user_lat, user_lon)
                    newOpenVenues[i].distance = distance;
                    if (distance > 999) {

                        let first = Math.floor(distance / 1000);
                        let remainder = parseInt(String(distance % 1000)[0]);
                        distance_text = (first + ',' + remainder + ' km');
                    } else {
                        distance_text = distance + ' m';
                    }
                    newOpenVenues[i].distance_text = distance_text;
                }

                newOpenVenues.sort((b, a) => b.distance - a.distance);

                // @ts-ignore
                setVenuesOpen(newOpenVenues);
            }
            if (venues_closed.length) {
                let newClosedVenues = venues_closed.map((item: any) => ({
                    ...item
                }));

                let distance = 0;
                let distance_text = '';

                for (let i = 0; i < newClosedVenues.length; i++) {

                    distance = getDistance(parseFloat(newClosedVenues[i].restaurant__gps_lat), parseFloat(newClosedVenues[i].restaurant__gps_lon), user_lat, user_lon)
                    newClosedVenues[i].distance = distance;
                    if (distance > 999) {

                        let first = Math.floor(distance / 1000);
                        let remainder = parseInt(String(distance % 1000)[0]);
                        distance_text = (first + ',' + remainder + ' km');
                    } else {
                        distance_text = distance + ' m';
                    }
                    newClosedVenues[i].distance_text = distance_text;
                }

                newClosedVenues.sort((b, a) => b.distance - a.distance);

                // @ts-ignore
                setVenuesClosed(newClosedVenues);

            }
        }
    }

    ////// --- Appearance Helper Functions ----- ///////

    const countFiltersSet = () => {
        const arr = [hasGastgarten, hasParkplatz, hasMittagsmenu, hasSchnell];
        const count = arr.filter(Boolean).length;
        return count;
    }

    function formatDateToTimeString(date: Date): string {
        // Get hours and minutes from the Date object
        const hours = date.getHours();
        const minutes = date.getMinutes();

        // Format the time string with leading zeros if needed
        const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

        // Combine hours and minutes into the "HH:mm" format
        const timeString = `${formattedHours}:${formattedMinutes}`;

        return timeString;
    }

    function formatDateToDateString(date: Date): string {
        // Get day, month, and year from the Date object
        const day = date.getDate();
        // Note: getMonth() returns zero-based month (0 for January, 1 for February, etc.)
        const month = date.getMonth() + 1; // Adding 1 to get the correct month
        const year = date.getFullYear();

        // Format the date string with leading zeros if needed
        const formattedDay = day < 10 ? `0${day}` : `${day}`;
        const formattedMonth = month < 10 ? `0${month}` : `${month}`;

        // Combine day, month, and year into the "DD.MM.YYYY" format
        const dateString = `${formattedDay}.${formattedMonth}.${year}`;

        return dateString;
    }

    function getDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
        const theta = lon1 - lon2;
        let dist = Math.sin(deg2rad(lat1)) * Math.sin(deg2rad(lat2)) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.cos(deg2rad(theta));
        dist = Math.acos(dist);

        const meters = dist * 6378137; // earth radius in meters at the latitude of Graz
        return Math.round(meters);

        function deg2rad(degrees: number): number {
            return degrees * (Math.PI / 180);
        }
    }

    function getVenueById(id: number, venues: any) {
        const foundVenue = venues.find((venue: { id: number; }) => venue.id === id);
        console.log(foundVenue);
        return foundVenue || null;
    }

    ////// Return Statement ///////   

    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
            categoriesLoaded && venuesLoaded ? (
                <ion-page class="sg-body">
                    <MetaHelmet 
                        title= {language === 'de' ? category?.name_de : category?.name_en}
                        desc= {language === 'de' ? category?.description_de : category?.description_en}
                    />
                    <ion-header class="ion-padding" id="header-category" mode="ios">
                        <div className="header-container">
                            <div className="header-container__title">
                                <h1 className="start-title left">
                                    {language === 'de' ? category?.name_de : category?.name_en}
                                </h1>
                                <span className="share-icon info" id="open-description-modal"></span>
                            </div>
                            <span onClick={() => shareUrl()} className="share-icon"></span>
                        </div>
                        <div className="filter-map-container">
                            <ion-segment class="segment" value={currentSlide}>
                                <ion-segment-button class="segment-button" onClick={() => changeSlide(0)} value={0}>
                                    <label>{t('list')}</label>
                                </ion-segment-button>
                                <ion-segment-button class="segment-button" onClick={() => changeSlide(1)} value={1}>
                                    <label>{t('map')}</label>
                                </ion-segment-button>
                            </ion-segment>
                            <span className="sg-filter" id="open-filter-modal">
                                {countFiltersSet() > 0 && <span>{countFiltersSet()}</span>}
                            </span>
                        </div>
                    </ion-header>

                    {/* Modale */}
                    <>
                        {/* Description Modal */}
                        <ion-modal class="filter-modal" trigger="open-description-modal" initial-breakpoint="0.25" breakpoints="['0', '0.25', '0.5', '0.75']">
                            <span className="filter-header">{t('description')}</span>
                            <div className="filter-group ion-padding">
                                <div className="modal-description-text">
                                    {language === 'de' ? category?.description_de : category?.description_en}

                                </div>
                            </div>
                        </ion-modal>

                        {/* Filter Modal */}
                        <ion-modal trigger="open-filter-modal" initial-breakpoint="0.25" breakpoints="[0, 0.25, 0.5, 0.75]">
                            <span className="filter-header">Filter</span>
                                <div className="filter-group ion-padding">
                                    <div className="filter-grid">
                                        <div
                                            style={{ backgroundImage: `url("/icons/gastgarten-${hasGastgarten}.svg")` }}
                                            onClick={() => addFilter(9)}
                                            className={`filter-grid__card garden ${hasGastgarten}`}
                                        >
                                            {t('gastgarten')}
                                        </div>
                                        <div
                                            style={{ backgroundImage: `url("/icons/mittagsmenu-${hasMittagsmenu}.svg")` }}
                                            onClick={() => addFilter(22)}
                                            className={`filter-grid__card garden ${hasMittagsmenu}`}
                                        >
                                            {t('mittagsmenu')}
                                        </div>
                                        <div
                                            style={{ backgroundImage: `url("/icons/parkplatz-${hasParkplatz}.svg")` }}
                                            onClick={() => addFilter(129)}
                                            className={`filter-grid__card garden ${hasParkplatz}`}
                                        >
                                            {t('parking')}
                                        </div>
                                        <div
                                            style={{ backgroundImage: `url("/icons/schnelles_essen-${hasSchnell}.svg")` }}
                                            onClick={() => addFilter(138)}
                                            className={`filter-grid__card garden ${hasSchnell}`}
                                        >
                                            {t('schnell')}
                                        </div>
                                    </div>
                                </div>
                        </ion-modal>

                        {/* Sortier Modal */}
                        <ion-modal class="sortby-modal" ref={sortingModal} trigger="open-sortby-modal" initial-breakpoint="0.25" breakpoints="['0', '0.25', '0.5', '0.75', '1']">
                            <span className="filter-header">{t('sorting_order')}</span>
                                <div className="filter-group sorting">
                                    <ion-radio-group id="radioSorting" value={sortingPreference}>
                                        <ion-radio justify="start" value="popularity" onClick={() => changeSortingPreference("popularity")}>
                                            <label>{t('popularity')}</label>
                                        </ion-radio>
                                        <br />
                                        <ion-radio justify="start" value="distance" onClick={() => changeSortingPreference("distance")}>
                                            <label>{t('distance')}</label>
                                        </ion-radio>
                                    </ion-radio-group>
                                </div>
                        </ion-modal>
                    </>

                    {/* Date & Time */}
                    < div className="date-time-circle" >
                        <ion-datetime-button id="button-datetime" class={`filter-button button-datetime ${datetimeFilterOn}`} datetime="datetime-wheel"></ion-datetime-button>
                        <ion-modal keepContentsMounted="true">
                            <div>
                                <ion-datetime
                                    show-default-buttons={true}
                                    prefer-wheel={true}
                                    id="datetime-wheel"
                                    done-text={t('activate')}
                                    cancel-text={t('disable')}
                                    locale={language+'-de'}
                                    first-day-of-week={1}
                                    minute-values="0, 15, 30, 45"
                                ></ion-datetime>
                            </div>
                        </ion-modal>
                        {
                            datetimeFilterOn ? (
                                <div className="date-spans active">
                                    <span className="time">{formatDateToTimeString(datetimeValue)}</span>
                                    <span className="date">{formatDateToDateString(datetimeValue)}</span>
                                </div>
                            ) : (
                                <div className="date-spans">
                                    <span className="time">{formatDateToTimeString(datetimeValue)}</span>
                                    <span className="date">{formatDateToDateString(datetimeValue)}</span>
                                </div>
                            )
                        }
                    </div >


                    <div className="slider-container">
                        <Slider ref={sliderRef} {...sliderSettings}>
                            <div className="slide">
                                <span id="open-sortby-modal" className="sortedBy">
                                    {t('sortedBy')}
                                    <strong>{t(sortingPreference)}</strong>
                                    <span className="chevronDown" />
                                </span>
                                <div className="venue-list ion-padding">
                                    {venuesOpen.map((item) => (
                                        <VenueCard
                                            key={item['id']}
                                            slug={item['slug']}
                                            name={item['title']}
                                            status={item['status'] + (sortingPreference === 'distance' ? ' | ' + item['distance_text'] : '')}
                                            additionalInformation=""
                                        />
                                    ))}
                                    {venuesClosed.map((item) => (
                                        <VenueCard
                                            key={item['id']}
                                            slug={item['slug']}
                                            name={item['title']}
                                            status={item['status'] + (sortingPreference === 'distance' ? ' | ' + item['distance_text'] : '')}
                                            additionalInformation=""
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="slide">
                                <CategoryMap markers={venuesMarker} onMarkerSelected={handleMarkerSelected}></CategoryMap>
                                {selectedMarkerID !== -1 && (
                                    <Link className="venue-list__item map" to={`/betriebe/${getVenueById(selectedMarkerID, venues)?.slug}`}>
                                        <h3 className="venue-list__item-name">{getVenueById(selectedMarkerID, venues)?.title}</h3>
                                        <p className="venue-list__item-hours">{getVenueById(selectedMarkerID, venuesOpen)?.status}</p>
                                        <p className="venue-list__item-hours">{getVenueById(selectedMarkerID, venuesClosed)?.status}</p>
                                    </Link>
                                )}
                            </div>
                        </Slider>
                    </div>
                </ion-page >
            ) : (
                <ion-page class="sg-body">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>Loading....</ion-title>
                        </ion-toolbar>
                    </ion-header>
                    <div className="sg-spinner"></div>
                </ion-page>
            )
        )
    );
}

export default pageTransition(Category);