import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
        en: {
            translation: {
                "start_title": "What are you up for?",
                "news": "News",
                "essen": "Eat",
                "trinken": "Drink",
                "aktivitaeten": "Activities",
                "events": "Events",
                "settings": "Settings",
                "look_language": "Look & Language",
                "sorting_order": "Sorting of Venues",
                "distance": "Distance",
                "light_theme": "Light Theme",
                "dark_theme": "Dark Theme",
                "german": "German",
                "english": "English",
                "search": "Search",
                "data-privacy": "Data Privacy",
                "language": "Language",
                "welcomeText": "Welcome to smartGastro!",
                "consentInfo": "You can change your preferences later in Settings.",
                "cookiesInfo": "Detailed information you can find at  ",
                "consent-ok": "ACCEPT & CONTINUE",
                "sortedBy": "Sorted by: ",
                "popularity": "Popularity",
                "gastgarten": "Outdoor Area",
                "mittagsmenu": "Lunch Menu",
                "parking": "Parking",
                "categories": "Categories",
                "todayUntil": "Open until ",
                "closed": "Closed",
                "close": "close",
                "opensAt": ", opens at ",
                "openingHours": "Opening Hours",
                "menu": "Menu",
                "gallery": "Gallery",
                "searchResults": "Search Results",
                "venues": "Venues",
                "home": "Home",
                "warm_kitchen": "Kitchen Hours",
                "statistics": "Statistics",
                "essential": "Essential",
                "essential-stats": "Detail",
                "map": "Map",
                "list": "List",
                "description": "Description",
                "chooseTime": "Date and time",
                "numberPeople": "Number of people",
                "book": "Send booking request",
                "allCategories": "All Categories",
                "schnell": "Fastfood",
                "about-us": "About Us",
                "not-found": "Not Found",
                "activate": "Activate",
                "disable": "Disable",
            }
        },
        de: {
            translation: {
                "start_title": "Worauf hast du Lust?",
                "news": "Aktuelles",
                "essen": "Essen",
                "trinken": "Trinken",
                "aktivitaeten": "Aktivitäten",
                "events": "Events",
                "settings": "Einstellungen",
                "look_language": "Look & Sprache",
                "sorting_order": "Sortierung",
                "distance": "Distanz",
                "light_theme": "Helles Farbschema",
                "dark_theme": "Dunkles Farbschema",
                "german": "Deutsch",
                "english": "Englisch",
                "search": "Suche",
                "data-privacy": "Datenschutz",
                "language": "Sprache",
                "welcomeText": "Willkommen auf smartGastro!",
                "consentInfo": "Du kannst deine Auswahl jederzeit in den Einstellungen anpassen.",
                "cookiesInfo": "Weitere Informationen findest du unter ",
                "consent-ok": "ZUSTIMMEN & FORTFAHREN",
                "sortedBy": "Sortiert nach: ",
                "popularity": "Popularität",
                "gastgarten": "Gastgarten",
                "mittagsmenu": "Mittagsmenü",
                "parking": "Parkplatz",
                "categories": "Kategorien",
                "todayUntil": "Geöffnet bis ",
                "closed": "Geschlossen",
                "close": "schließen",
                "opensAt": ", öffnet um ",
                "openingHours": "Öffnungszeiten",
                "menu": "Speisekarte",
                "gallery": "Galerie",
                "searchResults": "Suchergebnisse",
                "venues": "Betriebe",
                "home": "Start",
                "warm_kitchen": "Warme Küche",
                "statistics": "Statistikdaten",
                "essential": "Essenziell",
                "essential-stats": "Detail",
                "map": "Karte",
                "list": "Liste",
                "description": "Beschreibung",
                "chooseTime": "Datum und Uhrzeit",
                "numberPeople": "Personenanzahl",
                "book": "Reservierungsanfrage senden",
                "allCategories": "Alle Kategorien",
                "schnell": "Schnelle Küche",
                "about-us": "Über Uns",
                "not-found": "Nicht Gefunden",
                "activate": "Aktivieren",
                "disable": "Zurücksetzen",
            }
        },
    }
;

i18n
    .use(LanguageDetector) // Sprachdetektor einbinden
    .use(initReactI18next) // Bindet i18next an React
    .init({
        resources, // Lokale Übersetzungen bereitstellen
        fallbackLng: 'de', // Fallback-Sprache, falls keine passende Sprache gefunden wird
        detection: {
            order: ['localStorage', 'navigator'], // Reihenfolge der Spracherkennung
            caches: ['localStorage'] // Speicherung der erkannten Sprache
        },
        interpolation: {
            escapeValue: false, // React kümmert sich um das Escaping
        },
    });

export default i18n;