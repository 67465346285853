import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SettingsPanel from "../components/settingsPanel";
import pageTransition from "../components/pageTransition";
import StartSettings from "../components/startSettings";
import { useLocation } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";



const Settings: React.FC = () => {
    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));
    const { t } = useTranslation();

    const location = useLocation();
    const { trackPageView } = useMatomo();
  
    useEffect(() => {
      trackPageView({
        href: window.location, // URL der aktuellen Seite
      });
    }, [location]);
    
    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
        <ion-page class="sg-body">
            <MetaHelmet 
                title={t('settings')}
            />
            <div className="settings-page">
                <div
                    className="logo-container settings"
                    style={{ backgroundImage: `url("/icons/smartGastro-rund-white.svg")` }}
                >
                    <h1
                        style={{ width: '300px' }}
                        className="start-title">{t('settings')}</h1>
                </div>
                <SettingsPanel />
            </div>
        </ion-page>
        )
    );
}

export default pageTransition(Settings);

