import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import StartSettings from "../components/startSettings";
import { shareUrl } from "../helper/Data";
import pageTransition from "../components/pageTransition";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";

interface PostProps {
    newsLoaded: boolean;
}

// Interface für den Post
interface Post {
    id: number;
    slug: string;
    title: string;
    content: string;
    featured_media: string;
    thumbnail: string;
    publish_date: string;
}

const Post: React.FC<PostProps> = ({ newsLoaded }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Use `useLocation` to extract venue
    const location = useLocation();
    const path = location.pathname.substring(1);
    const postSlug = path.split('/')[1];

    const { trackPageView } = useMatomo();

    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));
    const [language] = useState(localStorage.getItem('i18nextLng') || 'de');

    const [post, setPost] = useState<Post | null>(null); // Verwende das Post-Interface

    function getSinglePost(posts: Post[], slug: string): Post | undefined {
        let result = posts.filter((item: Post) => item.slug === slug);
        return result[0];
    }

    useEffect(() => {
        if (!newsLoaded) return;
        trackPageView({
            href: window.location.href, // URL der aktuellen Seite
          });

        let tempPosts = localStorage.getItem('news');
        if (tempPosts) {
            const parsedPosts: Post[] = JSON.parse(tempPosts);
            const postItem = getSinglePost(parsedPosts, postSlug) || null;

            if (postItem){setPost(postItem);}
            else {navigate('/notfound');}  
        }
    }, [location, newsLoaded]);

    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
            newsLoaded ? (
                <ion-page class="sg-body post-page">
                <MetaHelmet 
                    title={post?.title}
                    desc={`Alle Details zu ${post?.title} in Graz findest du auf smartGastro. Entdecke Öffnungszeiten, Speisekarten und mehr!`}
                />
                    <div>
                        <ion-img class="post-image" src={post?.featured_media} />
                        <span className="post-date ion-padding">{post?.publish_date}</span>
                        <div className="header-container ion-padding news">
                            <h1 className="start-title">
                                {post?.title}
                            </h1>
                            <span onClick={() => shareUrl()} className="share-icon"></span>
                        </div>
                        <div className="news-content ion-padding" dangerouslySetInnerHTML={{ __html: post?.content || '' }} />
                    </div>
                </ion-page>
            ) : (
                <ion-page class="sg-body">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>Loading....</ion-title>
                        </ion-toolbar>
                    </ion-header>
                        <div className="sg-spinner"></div>
                </ion-page>
            )
        )
    );
}

export default pageTransition(Post);
