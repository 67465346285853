import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    calculateOpenHours,
    checkWarmKitchen,
    getOpenDOW,
    getVenueOpeningHours,
    openValueToTimeString
} from "../helper/Filter";
import { useTranslation } from "react-i18next";
import { getParentSlugOfId, shareUrl } from "../helper/Data";
import StartSettings from "../components/startSettings";
//React Slick Imports
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pageTransition from "../components/pageTransition";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";

interface VenueProps {
    categoriesLoaded: boolean;
    venuesLoaded: boolean;
}

interface Venue {
    id: number;
    title: string;
    slug: string;
    category: number[];
    parent_category: number[];
    restaurant__gallery: string[];
    restaurant__closed: {
        from: string;
        until: string;
    };
    restaurant__mittagsteller: string;
    restaurant__website: string;
    restaurant__menu: string;
    restaurant__gps_lon: string;
    restaurant__gps_lat: string;
    restaurant__name: string;
    restaurant__opening_hours: {
        "restaurant__opening_hours-open": string;
        "restaurant__opening_hours-close": string;
        "restaurant__opening_hours-dow_till": string;
        "restaurant__opening_hours-warm_cuisine": boolean;
        "restaurant__opening_hours-dow_from": string;
    }[];
    restaurant__email: string;
    restaurant__phone_number: string;
    restaurant__address: string;
    restaurant__google_key: string;
    restaurant__views: string;
    google_key: string;
}

const Venue: React.FC<VenueProps> = ({ categoriesLoaded, venuesLoaded }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Use `useLocation` to extract venue
    const location = useLocation();
    const path = location.pathname.substring(1);
    const venueSlug = path.split('/')[1];

    const { trackPageView } = useMatomo();

    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));
    const [language] = useState(localStorage.getItem('i18nextLng') || 'de');

    const [venue, setVenue] = useState<Venue | null>(null);
    const [categories, setCategories] = useState<any[]>([]);
    const [openStatus, setOpenStatus] = useState('geschlossen');
    const [openDays, setOpenDays] = useState<any[]>([]);
    const [openDOW, setOpenDOW] = useState<any[]>([]);
    const [gallery, setGallery] = useState<string[]>([]);
    const [emailData, setEmailData] = useState({
        dateTime: formatDateTime(new Date().toISOString()),
        name: 'Max Mustermann',
        numberPeople: 2
    });

    const gallerySliderSettings = {
        dots: false,           // Keine Dots anzeigen
        infinite: true,       // Keine Endlosschleife
        slidesToShow: 1,       // Nur eine Slide anzeigen
        slidesToScroll: 1,
        autoplay: false,       // Kein automatisches Weiterblättern
        arrows: true,           // Pfeile für die Navigation (optional)
        swipe: true,
    };

    ////// Modal Definition & Functions ///////   

    const modal = useRef<HTMLIonModalElement>(null);

    function dismiss() {
        modal.current?.dismiss();
    }

    //useEffect bei verlassen
    useEffect(() => {
        return () => {
            //console.log('unmount');
        };
      }, []);

    ////// Reservation Functions /////// 

    const handleNumberChange = (e: any) => {
        const { name, value } = e.target;
        setEmailData({
            ...emailData,
            [name]: value
        });
    };

    const changeNumberPeople = (change: number) => {
        setEmailData(prevData => {
            const newNumberPeople = prevData.numberPeople + change;
            return {
                ...prevData,
                numberPeople: newNumberPeople < 1 ? 1 : newNumberPeople
            };
        });
    };

    const handleDateChange = (e: any) => {
        const formattedDate = formatDateTime(e.detail.value);
        setEmailData({
            ...emailData,
            dateTime: formattedDate
        });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        sendEmail();
    };

    const sendEmail = () => {
        if (!venue) return;

        const textGerman = 'Liebes Team von ' + venue.title + ',%0A'
            + 'ich würde gerne am ' + emailData.dateTime + ' bei euch reservieren. Bitte um Bestätigung meiner Anfrage für '
            + emailData.numberPeople + ' Personen.%0A%0A'
            + 'Diese Email wurde von einem Nutzer der Grazer Website www.smartgastro.at erstellt.%0A%0A'
            + 'Vielen Dank und liebe Grüße,%0A' + emailData.name;

        const textEnglish = 'Dear ' + venue.title + ' team,%0A'
            + 'I would like to reserve a table for ' + emailData.numberPeople + ' people on ' + emailData.dateTime
            + '%0APlease confirm my reservation.'
            + 'This message was automatically generated by a user of smartgastro.at.%0A%0A'
            + 'Thank you and best regards,%0A' + emailData.name;

        const text = language === 'german' ? textGerman : textEnglish;
        const subject = language === 'german' ? 'Reservierungsanfrage' : 'Booking request';

        const mailtoLink = `mailto:${venue.restaurant__email}?subject=${subject}&body=${text}`;
        window.location.href = mailtoLink;
    };

    ////// Helper Functions /////// 

    function formatDateTime(dateString: string) {
        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let text = `${day}.${month}.${year}` + (language === 'german' ? ' um ' : ' at ') + `${hours}:${minutes}`;

        return text;
    }

    function getVenueBySlug(slug: string, venues: Venue[]): Venue | null {
        const foundVenue = venues.find((venue) => venue.slug === slug);
        return foundVenue || null;
    }

    function getCategoriesById(categoryIds: number[]) {
        let allCategories = localStorage.getItem('categories')
        if (allCategories && allCategories.length) {
            allCategories = JSON.parse(allCategories);

            let categories = [];

            for (let counter = 0; counter < categoryIds.length; counter++) {
                // @ts-ignore
                for (let i = 0; i < allCategories.length; i++) {
                    // @ts-ignore
                    if (categoryIds[counter] === allCategories[i].id) {
                        let cat = {
                            // @ts-ignore
                            'slug': allCategories[i].slug,
                            'id': categoryIds[counter],
                            // @ts-ignore
                            'name_de': allCategories[i].name_de,
                            // @ts-ignore
                            'name_en': allCategories[i].name_en,
                            // @ts-ignore
                            'parent_id': allCategories[i].parent_id
                        };

                        categories.push(cat);
                    }
                }
            }
            setCategories(categories);
        }
    }

    function getOpeningHours(openingHours: Venue["restaurant__opening_hours"], language: string | null) {
        // @ts-ignore
        setOpenDOW(getOpenDOW(openingHours, language));
        // @ts-ignore
        let isWarmKitchen = checkWarmKitchen(openingHours);

        if (isWarmKitchen) {
            // @ts-ignore
            let days = calculateOpenHours(openingHours, true, language || 'german');
            setOpenDays(days);
        } else {
            // @ts-ignore
            let days = calculateOpenHours(openingHours, false, language || 'german');
            setOpenDays(days);
        }

        let currentDate = new Date();
        let hour = currentDate.getHours();
        let minutes = currentDate.getMinutes() / 60;
        let dow = currentDate.getDay();
        if (dow == 0) {
            dow = 7
        } //Sonntag auf europäischen Wert
        let time = hour + minutes;

        let openValue = getVenueOpeningHours(openingHours, time, dow);
        if (openValue > 0) {
            setOpenStatus(t('todayUntil') + openValueToTimeString(openValue));
        } else if (openValue < 0) {
            setOpenStatus(t('closed') + ' - ' + t('opensAt') + openValueToTimeString((-openValue)));
        } else {
            setOpenStatus(t('closed'));
        }

    }

    ////// Page useEffect ///////   
    useEffect(() => {
        if (!categoriesLoaded || !venuesLoaded) return;
        trackPageView({
            href: window.location.href, // URL der aktuellen Seite
          });

        let tempVenues = localStorage.getItem('venues');
        if (tempVenues) {
            let venues = JSON.parse(tempVenues);
            const currentVenue = getVenueBySlug(venueSlug || '', venues);

            if (currentVenue) {
                setVenue(currentVenue);
                setGallery(currentVenue.restaurant__gallery);
                getCategoriesById(currentVenue.category);
                getOpeningHours(currentVenue.restaurant__opening_hours, language || 'german');
            }
            else {
                navigate('/notfound');
            }
        }
    }, [location, categoriesLoaded, venuesLoaded]);


    ////// Return Statement ///////   
    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
            categoriesLoaded && venuesLoaded && venue ? (
                <ion-page class="sg-body">
                <MetaHelmet 
                    title={venue.title}
                    desc={`Alle Details zu ${venue.title} in Graz findest du auf smartGastro. Entdecke Öffnungszeiten, Speisekarten und mehr!`}
                />
                    <ion-header mode="ios">
                        <div className="header-container venue ion-padding">
                            <h1 className="start-title left">{venue.title}</h1>
                            <span onClick={() => shareUrl()} className="share-icon"></span>
                        </div>
                    </ion-header>
                    {/* Grid Container */}
                    <div className="grid-container venue">
                        <div className="venue-card gallery" id="gallery-modal">
                            {gallery.length > 0 && (
                                <>
                                    <img src={gallery[0]} />
                                    <svg
                                        className="photo-svg"
                                        version="1.1"
                                        id="Ebene_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 30 30"
                                        xmlSpace="preserve"
                                        fill="#fff"
                                    >
                                        <g>
                                            <path className="st0" d="M30,18.3c0-2.7,0-5.4,0-8c0-1.3-0.5-2.5-1.6-3.4c-0.7-0.6-1.5-1-2.4-1c-0.3,0-0.6,0-0.9,0c-0.1,0-0.3,0-0.4,0
		l-0.4,0c-0.5,0-1,0-1.5,0c-0.3-1-0.7-1.9-1.3-2.6c-0.7-0.9-1.5-1.5-2.5-1.7c-0.1,0-0.3-0.1-0.4-0.1c-2.3,0-4.6,0-6.9,0
		c-1.3,0-2.4,0.6-3.3,1.8C7.9,4,7.5,4.8,7.2,5.8l-0.1,0L7,5.8c-0.9,0-1.8,0-2.7,0c-2.2,0-4,1.5-4.3,3.8l0,14.8l0.1,0.3
		c0,0.2,0.1,0.4,0.2,0.6c0.3,1.1,0.9,1.9,1.7,2.5c0.5,0.4,1.1,0.6,1.8,0.7l0.1-0.2c0,0,0,0,0,0l0,0.3l22.2,0l0.2,0
		c0.1,0,0.3,0,0.4-0.1c1.4-0.3,2.4-1.2,3-2.6c0.3-0.6,0.4-1.2,0.4-1.9C30,22.1,30,20.2,30,18.3z M2.4,10.1c0-1,0.7-1.8,1.7-1.9
		c0.3,0,0.6,0,0.8,0c0.1,0,0.2,0,0.3,0l0.2,0c0.6,0,1.2,0,1.8,0l0.9,0c0,0,0,0,0,0c0.5,0,1-0.4,1.1-0.9c0.2-0.9,0.5-1.6,0.8-2.2
		c0.3-0.5,0.6-0.8,0.9-1c0.2-0.2,0.5-0.2,0.7-0.2c2.2,0,4.5,0,6.7,0c0.1,0,0.2,0,0.4,0.1c0.5,0.2,0.8,0.6,1.2,1.2
		c0.3,0.6,0.6,1.2,0.8,2c0.2,0.7,0.6,1,1.3,1l3.6,0c0,0,0,0,0,0c0.8,0,1.4,0.4,1.8,1.2c0.1,0.3,0.2,0.6,0.2,0.9c0,3.4,0,6.9,0,10.3
		l0,3.4c0,0.8-0.5,1.7-1.2,1.9c-0.2,0.1-0.4,0.1-0.6,0.1c-2.8,0-5.7,0-8.5,0l-5.2,0c-2.6,0-5.1,0-7.7,0c0,0,0,0,0,0
		c-1.1,0-1.6-0.8-1.8-1.2c-0.1-0.3-0.2-0.7-0.2-1c0-3.5,0-7.1,0-10.6L2.4,10.1z"/>
                                            <path className="st0" d="M14.9,23.7C15,23.7,15,23.7,14.9,23.7c3.6,0,6.5-2.9,6.6-6.5v0c0-3.6-2.9-6.5-6.5-6.5c0,0,0,0,0,0
		c-3.6,0-6.5,2.9-6.5,6.5C8.4,20.7,11.3,23.6,14.9,23.7z M15,13C15,13,15,13,15,13c2.3,0,4.2,1.9,4.2,4.2c0,1.1-0.4,2.2-1.2,3
		c-0.8,0.8-1.8,1.2-2.9,1.2c0,0,0,0,0,0c-1.1,0-2.2-0.4-2.9-1.2c-0.8-0.8-1.2-1.8-1.2-3C10.8,14.8,12.7,13,15,13z"/>
                                            <path className="st0" d="M23.3,11.4C23.4,11.4,23.4,11.4,23.3,11.4c0.3,0,0.6,0,0.8,0c0.1,0,0.2,0,0.4,0l0.3,0c0.3,0,0.6,0,0.9,0
		c0.1,0,0.3,0,0.4-0.1c0.4-0.2,0.6-0.7,0.4-1.2c-0.1-0.4-0.5-0.7-0.9-0.7l-2.3,0c-0.5,0-0.8,0.3-0.9,0.8c-0.1,0.3,0,0.7,0.2,0.9
		C22.8,11.3,23.1,11.4,23.3,11.4z"/>
                                        </g> </svg>
                                </>
                            )}
                            {gallery.length < 1 && (
                                <div
                                    className="gallery-empty"
                                    style={{ backgroundImage: `url("/icons/photo-white.svg")` }}
                                ></div>
                            )}
                        </div>
                        <div className="venue-card hours" id="hours-modal">
                            <h2 className="venue-card__heading">{t('openingHours')}</h2>
                            <div className="venue-card__weekdays">
                                {openDOW.map((item) => {
                                    if (item.state == true) {
                                        return (
                                            <span className="weekday open" key={item.name}>
                                                <div>{item.name}</div>
                                            </span>
                                        );
                                    } else {
                                        return (
                                            <span className="weekday" key={item.name}>
                                                <div>{item.name}</div>
                                            </span>
                                        );
                                    }
                                })}
                            </div>
                            <span className="restaurant-open-status">{openStatus}</span>
                        </div>
                        <div className="venue-card categories" id="categories-modal">
                            <h2 className="venue-card__heading">{t('categories')}</h2>
                            <div>
                                {categories.map((item) => {
                                    if (categories.indexOf(item) < 4) {
                                        return (
                                            <span
                                                className="category-item"
                                                key={item.id}
                                                style={{
                                                    backgroundImage: `url("/icons/${item.slug}.svg")`,
                                                }}
                                            ></span>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                        <div className="venue-card map">
                            <span>{venue.google_key}</span>
                            <iframe
                                style={{ border: 0 }}
                                loading="lazy"
                                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyARvPmX_dtbPov8LDKkleiAYiKLGzyR5vg&q=place_id:${venue.restaurant__google_key}`}
                            ></iframe>
                        </div>

                        {/* Links Container */}
                        <div className="fullwidth-links-container">
                            {venue.restaurant__menu && (
                                <Link to={venue.restaurant__menu}
                                    style={{ backgroundImage: `url("/icons/menu2-green.svg")` }}
                                    className="venue-card menu half"
                                >
                                    {t('menu')}
                                </Link>
                            )}
                            {venue.restaurant__mittagsteller && (
                                <Link to={venue.restaurant__mittagsteller}
                                    style={{ backgroundImage: `url("/icons/menu-green.svg")` }}
                                    className="venue-card mittag half"
                                >
                                    {t('mittagsmenu')}
                                </Link>
                            )}
                        </div>
                    </div>

                    {/* Contact Container */}
                    <div className="venue-contact-container">
                        {venue.restaurant__email && (
                            <span
                                style={{ backgroundImage: `url("/icons/mail-green.svg")` }}
                                className="contact-link"
                                id="book-modal"
                            ></span>
                        )}
                        {venue.restaurant__website && (
                            <a
                                style={{ backgroundImage: `url("/icons/website-green.svg")` }}
                                className="contact-link"
                                href={venue.restaurant__website}
                            ></a>
                        )}
                        {venue.restaurant__phone_number && (
                            <a
                                style={{ backgroundImage: `url("/icons/phone-green.svg")` }}
                                className="contact-link"
                                href={`tel:${venue.restaurant__phone_number}`}
                            ></a>
                        )}
                    </div>
                    {/* Modale */}

                    <>
                        {/* Booking Modal */}
                        <ion-modal ref={modal} class="sg-modal booking" trigger="book-modal" initial-breakpoint={0.5} breakpoints="[0, 1]">
                            <form className="ion-padding" onSubmit={handleSubmit}>
                                <ion-list lines="none">
                                    <ion-item>
                                        <label style={{ paddingRight: '16px' }}>{t('chooseTime')}</label>
                                        <ion-datetime-button datetime="datetime"></ion-datetime-button>
                                        <ion-modal keepContentsMounted={true}>
                                            <div>
                                                <ion-datetime
                                                    name="dateTime"
                                                    onIonChange={handleDateChange}
                                                    show-default-buttons={true}
                                                    prefer-wheel={true}
                                                    id="datetime"
                                                    doneText="Anwenden"
                                                    cancelText="Zurücksetzen"
                                                    locale="de-de"
                                                    first-day-of-week={1}
                                                    minute-values="0, 15, 30, 45"
                                                ></ion-datetime>
                                            </div>
                                        </ion-modal>
                                    </ion-item>

                                    <ion-item>
                                        <label>{t('numberPeople')}</label>
                                        <span className="sg-arrow-button" onClick={() => changeNumberPeople(-1)}></span>
                                        <ion-input
                                            class="input-number-people"
                                            name="numberPeople"
                                            value={emailData.numberPeople}
                                            onIonChange={handleNumberChange}
                                            type="number"
                                            placeholder="2"
                                        ></ion-input>
                                        <span className="sg-arrow-button up" onClick={() => changeNumberPeople(1)}></span>
                                    </ion-item>

                                    <ion-item>
                                        <label>Name</label>
                                        <ion-input
                                            style={{ marginLeft: '48px' }}
                                            name="name"
                                            value={emailData.name}
                                            onIonChange={handleNumberChange}
                                            placeholder="Max Mustermann"
                                        ></ion-input>
                                    </ion-item>

                                    <button type="submit" className="sg-button">
                                        {t('book')}
                                    </button>
                                </ion-list>
                            </form>
                        </ion-modal>

                        {/* Categories Modal */}
                        <ion-modal ref={modal} class="sg-modal categories" trigger="categories-modal" initial-breakpoint={0.75} breakpoints="[0, 1]">
                            <ion-list lines="none" class="ion-padding">
                                {categories.map((item) => {
                                    return (
                                        <Link
                                            key={item.id}
                                            style={{ textDecoration: 'none' }}
                                            to={'/' + getParentSlugOfId(item.parent_id) + '/' + item.slug}
                                        >
                                            <ion-item>
                                                <div className="venue-modal-category__image" slot="start">
                                                    <img src={`/icons/${item.slug}.svg`} />
                                                </div>
                                                <label className="venue-modal-category__label">
                                                    {language === 'de' ? item.name_de : item.name_en}
                                                </label>
                                            </ion-item>
                                        </Link>
                                    );
                                })}
                            </ion-list>
                        </ion-modal>

                        {/* Hours Modal  */}
                        <ion-modal ref={modal} class="sg-modal hours" trigger="hours-modal" initial-breakpoint={0.75} breakpoints="[0, 1]">
                            <ion-list class="ion-padding">
                                {openDays.slice(0, 8).map((day) => {
                                    return (
                                        <ion-item key={day.name}>
                                            <label>
                                                <span>{day.name}</span>
                                                <p>{day.hours ? day.hours : t('closed')}</p>
                                                {day.hours_warm && (
                                                    <p>
                                                        {t('warm_kitchen')}: {day.hours_warm}
                                                    </p>
                                                )}
                                            </label>
                                        </ion-item>
                                    );
                                })}
                                {openDays.slice(8, 9).map((day) => {
                                    return (
                                        day.hours && (
                                            <ion-item key={day.name}>
                                                <label>
                                                    <span>{day.name}</span>
                                                    <p>{day.hours}</p>
                                                    {day.hours_warm && (
                                                        <p>
                                                            {t('warm_kitchen')}: {day.hours_warm}
                                                        </p>
                                                    )}
                                                </label>
                                            </ion-item>
                                        )
                                    );
                                })}
                            </ion-list>
                        </ion-modal>

                        {/* Gallery Modal */}
                        <ion-modal
                            id="example-modal"
                            trigger="gallery-modal"
                            class="sg-modal gallery"
                            ref={modal}
                            initialBreakpoint={1}
                            breakpoints={[0, 1]}
                        >
                            <ion-toolbar color="green">
                                <ion-title>{venue.title}</ion-title>
                                <ion-buttons slot="end">
                                    <ion-button onClick={() => dismiss()}>{t('close')}</ion-button>
                                </ion-buttons>
                            </ion-toolbar>
                            <div>
                                {gallery.length > 0 && (
                                    <Slider {...gallerySliderSettings}>
                                        {gallery.map((src) => {
                                            return (
                                                <div className="slide">
                                                    <img src={src} />
                                                </div>
                                            );
                                        })}

                                    </Slider>
                                )}
                                {gallery.length < 1 && (
                                    <>
                                        <div className="venue-no-fotos">
                                            Leider fehlen uns noch Bilder für diesen Betrieb.<br />
                                            Mit einem Klick auf "Fotos zusenden" kannst du dem Gastronomen bei der Außenwahrnehmung
                                            unterstützen.
                                            <a
                                                className="sg-button"
                                                href={`mailto:bilder@smartgastro.at?subject=Bilder ${venue.title}&amp;body=Liebes SmartGastro Team, %0D%0Ahiermit sende ich euch im Anhang Bilder vom Betrieb ${venue.title}.
    %0D%0AIch bestätige, dass ich für diese Bilder das Urheberrecht besitze und euch die Rechte zur Nutzung der Bilder zur Verfügung stelle.`}
                                            >
                                                FOTOS ZUSENDEN
                                            </a>
                                        </div>
                                    </>
                                )}
                            </div>
                        </ion-modal>
                    </>
                </ion-page>
            ) : (
                <ion-page class="sg-body">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>Loading....</ion-title>
                        </ion-toolbar>
                    </ion-header>
                    <div className="sg-spinner"></div>
                </ion-page>
            )
        )
    );
}

export default pageTransition(Venue);
