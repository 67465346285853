
export function filterVenues(allVenues: any[], categories: number[]) {
    let venues = allVenues.filter(function (el: any) {
        if (categories.every(val => el.category.includes(val) || el.parent_category.includes(val)))  {
            return true;
        }
        return false;
    });

    return venues;
}

export function filterEvents(allEvents: any[], categories: number[]) {
    console.log("filter events");
    let events = allEvents.filter(function (el: any) {
        if (categories.every(val => el.category.includes(val))) {
            return true;
        }
        return false;
    });

    console.log(events);

    return events;
}

export function shareUrl() {
    navigator.share({
        url: document.URL,
        title: document.title
    });
}

export function getParentSlugOfId(parentID: number) {
    let parentSlug = "";
    switch (parentID) {
        case 83:
            parentSlug = 'essen';
            break;
        case 84:
            parentSlug = 'trinken';
            break;
        case 85:
            parentSlug = 'aktivitaeten';
            break;
        case 86:
            parentSlug = 'events';
            break;
    }

    return parentSlug;
}



