import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function SettingsPanel() {
    const { t, i18n } = useTranslation();
    const [theme, setTheme] = useState('light');
    const [language, setLanguage] = useState('de');
    const [sorting, setSorting] = useState('popularity');
    const [statistics, setStatistics] = useState('detail');

    const changeColorTheme = (ev: CustomEvent) => {
        localStorage.setItem('color-scheme', ev.detail.value);
        document.body.setAttribute('data-scheme', ev.detail.value);
        console.log(ev.detail.value);
        setTheme(ev.detail.value);
    };

    const changeLanguage = (lang: string) => {
        console.log("Selected language:", lang);
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };


    const changeSortingPreference = (sorting: string) => {
        console.log("Sorting set to:", sorting);
        localStorage.setItem('sorting', sorting);
        setSorting(sorting);
    };

    const changeStatistics = (stats: string) => {
        console.log("Statistics set to:", stats);
        localStorage.setItem('statistics', stats);
        setStatistics(stats);
    };

    useEffect(() => {
        //const localTheme = localStorage.getItem('color-scheme') ?? 'light';
        //setTheme(localTheme);

        const sortingPreference = localStorage.getItem('sorting') ?? 'popularity';
        setSorting(sortingPreference);

        const language = localStorage.getItem('i18nextLng') ?? 'de';
        setLanguage(language);
    }, []);

    return (
        <>
            <ion-list-header class="settings-heading">{t('language')}</ion-list-header>
            <ion-list mode="ios" class="preference-item" inset={true}>
                <ion-segment class="segment" id="segmentLanguage" value={language}>
                    <ion-segment-button class="segment-button" onClick={() => changeLanguage('de')} value="de">
                        <label>{t('german')}</label>
                    </ion-segment-button>
                    <ion-segment-button class="segment-button" onClick={() => changeLanguage('en')} value="en">
                        <label>{t('english')}</label>
                    </ion-segment-button>
                </ion-segment>
            </ion-list>

            <ion-list-header class="settings-heading">{t('sorting_order')}</ion-list-header>
            <ion-list mode="ios" class="preference-item" inset={true}>
                <ion-segment class="segment" id="segmentSorting" value={sorting}>
                    <ion-segment-button class="segment-button" onClick={() => changeSortingPreference('popularity')} value="popularity">
                        <label>{t('popularity')}</label>
                    </ion-segment-button>
                    <ion-segment-button class="segment-button" onClick={() => changeSortingPreference('distance')} value="distance">
                        <label>{t('distance')}</label>
                    </ion-segment-button>
                </ion-segment>
            </ion-list>

            {/*<ion-list-header class="settings-heading">{t('statistics')}</ion-list-header>
            <ion-list mode="ios" class="preference-item" inset={true}>
                <ion-segment class="segment" id="segmentSorting" value="detail">
                    <ion-segment-button class="segment-button" onClick={() => changeStatistics('essential')} value="essential">
                        <label>{t('essential')}</label>
                    </ion-segment-button>
                    <ion-segment-button class="segment-button" onClick={() => changeStatistics('detail')} value="detail">
                        <label>{t('essential-stats')}</label>
                    </ion-segment-button>
                </ion-segment>
            </ion-list> */} 

            <div className="data-privacy consent-info">
                <Link to="/about-us">{t('about-us')}</Link>
                <Link to="/datenschutz">{t('data-privacy')}</Link>
                <Link to="/impressum">Impressum</Link>
            </div>
        </>
    );
}
