import React, { useRef, useState, useEffect } from "react";
import { filterEvents, shareUrl } from "../helper/Data";
import { getDayOfIndex } from "../helper/Filter";
import VenueCard from "../components/venueCard";
import CategoryMap from "../components/categoryMap";
import pageTransition from "../components/pageTransition";

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link, useLocation, useNavigate } from 'react-router-dom';
import StartSettings from "../components/startSettings";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";

interface WeeklyEventsProps {
    categoriesLoaded: boolean;
    eventsLoaded: boolean;
}

interface Category {
    id: number;
    parent_id: number;
    slug: string;
    name_de: string;
    description_de: string;
    name_en: string;
    description_en: string;
    search_str: string;
    venue_count: number;
}

const WeeklyEvent: React.FC<WeeklyEventsProps> = ({ categoriesLoaded, eventsLoaded }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Use `useLocation` to extract category
    const location = useLocation();
    const pathname = location.pathname.endsWith('/')
    ? location.pathname.slice(0, -1)
    : location.pathname;
    const segments = pathname.substring(1).split('/'); 
    const categorySlug = segments.pop();

    const { trackPageView } = useMatomo();

    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));
    const [language] = useState(localStorage.getItem('i18nextLng') || 'de');

    // 1. venues of category
    const [events, setEvents] = useState<any[]>([])
    const [eventsByDay, setEventsByDay] = useState<any[]>([])

    // 2. mapped venues for the marker
    const [eventsMarker, setEventsMarker] = useState([]);

    const [category, setCategory] = useState<Category | null>(null);

    // Slider
    const sliderRef = useRef<Slider>(null);

    // Callback function to handle slide change
    const handleAfterChange = (currentIndex: number) => {
        console.log('Slide changed to:', currentIndex);
        setCurrentSlide(currentIndex);
    };

    // Callback function before the slide changes
    const handleBeforeChange = (oldIndex: number, newIndex: number) => {
        console.log('Slide is about to change from:', oldIndex, 'to:', newIndex);
        if (newIndex === 1) {
            setSliderSettings((prevSettings) => ({
                ...prevSettings,
                swipe: false,
            }));

            // @ts-ignore
            document.getElementById('header-category').className = 'transparent ion-padding';
        } else {
            setSliderSettings((prevSettings) => ({
                ...prevSettings,
                swipe: true,
            }));

            // @ts-ignore
            document.getElementById('header-category').className = 'ion-padding';
        }
    };

    const [sliderSettings, setSliderSettings] = useState({
        dots: false,           // Keine Dots anzeigen
        infinite: false,       // Keine Endlosschleife
        speed: 500,
        slidesToShow: 1,       // Nur eine Slide anzeigen
        slidesToScroll: 1,
        autoplay: false,       // Kein automatisches Weiterblättern
        arrows: false,           // Pfeile für die Navigation (optional)
        afterChange: handleAfterChange, // Detect slide change after it happens
        beforeChange: handleBeforeChange,
        swipe: true,
    });

    const changeSlide = (index: number) => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(index);
        }
    }

    const [currentSlide, setCurrentSlide] = useState(0);

    //map marker
    const [selectedMarkerID, setSelectedMarkerID] = useState<number>(-1);

    const handleMarkerSelected = (index: number | null) => {
        if (index !== null) {
            setSelectedMarkerID(index);
            console.log(index);
        }
    };


    useEffect(() => {
        if (!categoriesLoaded || !eventsLoaded) return;
        trackPageView({
            href: window.location.href, // URL der aktuellen Seite
          });

        // Get Category
        const categories: any = localStorage.getItem('categories');
        if (categories) {
            let currentCategoryId = -1;
            // @ts-ignore
            JSON.parse(categories).forEach((item: any) => {
                if (item.slug === categorySlug) {
                    console.log('Item:', item);
                    setCategory(item);
                    console.log('Category after set:', category);

                    currentCategoryId = item.id;
                }
            })

            if (currentCategoryId < 0) {
                navigate('/notfound');
            }


            // Get events
            // @ts-ignore
            let tempEvents: any = JSON.parse(localStorage.getItem('events'));
            tempEvents = filterEvents(tempEvents, [currentCategoryId]);
            setEvents(tempEvents);

            type Event = {
                id: number;
                type: string;
                venue_title: string;
                venue_slug: string;
                restaurant__gps_lon: string;
                restaurant__gps_lat: string;
                category: number[];
                description_1: string;
                description_2: string;
                dow: string;
                dow_till: string;
                date: string | null;
              };
            
            // @ts-ignore           
            function organizeEventsByDay(events: Event[]): Event[][] {
                // Initialize an array of 7 arrays for each day of the week
                const eventsByDay: Event[][] = [[], [], [], [], [], [], []];
              
                events.forEach(event => {
                  const startDay = parseInt(event.dow) - 1; // Adjusting for array index (0-6)
                  const endDay = event.dow_till ? parseInt(event.dow_till) - 1 : startDay;
              
                  for (let day = startDay; day <= endDay; day++) {
                    eventsByDay[day].push(event);
                  }
                });
              
                return eventsByDay;
            }
              
              // Usage
              const eventsOrganizedByDay = organizeEventsByDay(tempEvents);
              setEventsByDay(eventsOrganizedByDay);
              console.log("organized events");
              console.log(eventsOrganizedByDay);

            // @ts-ignore
            const newmarkers = tempEvents.map(({ id, venue_title, restaurant__gps_lat, restaurant__gps_lon }) => ({
                id,
                title: venue_title, // Changed field name from venue_title to title
                isOpen: true, // Set isOpen to always be true
                restaurant__gps_lat,
                restaurant__gps_lon,
            }));

            // @ts-ignore   
            newmarkers.sort((a, b) => b.restaurant__gps_lat - a.restaurant__gps_lat);
            // @ts-ignore
            setEventsMarker(newmarkers);

        }
    }, [location, categoriesLoaded, eventsLoaded]);


    function getEventById(id: number, events: any[]) {
        const foundEvent = events.find((event: { id: number; }) => event.id === id);
        console.log(foundEvent);
        return foundEvent || null;
    }


    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
        categoriesLoaded && eventsLoaded ? (
            <ion-page class="sg-body">
                <MetaHelmet 
                    title= {language === 'de' ? category?.name_de : category?.name_en}
                    desc= {language === 'de' ? category?.description_de : category?.description_en}
                />
                <ion-header class="ion-padding" id="header-category" mode="ios">
                    <div className="header-container">
                        <div className="header-container__title">
                            <h1 className="start-title left">
                                {language === 'de' ? category?.name_de : category?.name_en}
                            </h1>
                            <span className="share-icon info" id="open-description-modal"></span>
                        </div>
                        <span onClick={() => shareUrl()} className="share-icon"></span>
                    </div>
                    <div className="filter-map-container">
                        <ion-segment class="segment" value={currentSlide}>
                            <ion-segment-button class="segment-button" onClick={() => changeSlide(0)} value={0}>
                                <label>{t('list')}</label>
                            </ion-segment-button>
                            <ion-segment-button class="segment-button" onClick={() => changeSlide(1)} value={1}>
                                <label>{t('map')}</label>
                            </ion-segment-button>
                        </ion-segment>
                    </div>
                </ion-header>
                    <div className="slider-container">
                        <Slider ref={sliderRef} {...sliderSettings}>
                            <div className="slide">
                                <div className="venue-list ion-padding event">
                                {eventsByDay.map((dailyEvents, index) => {
                                if (dailyEvents.length > 0) {
                                return (
                                    <div key={index}>
                                        <h2>{getDayOfIndex(index + 1,language)}</h2>
                                        {dailyEvents.map((event: { id: React.Key | null | undefined; venue_slug: string; venue_title: string; description_1: string; description_2: string; }) => (
                                            <VenueCard
                                            key={event.id}
                                            slug={event.venue_slug}
                                            name={event.venue_title}
                                            status={event.description_1}
                                            additionalInformation={event.description_2}
                                            />
                                        ))}
                                    </div>
                                    );
                                    }
                                    return null;
                                    })}
                                </div>
                            </div>
                            <div className="slide">
                                <CategoryMap markers={eventsMarker} onMarkerSelected={handleMarkerSelected}></CategoryMap>
                                {(selectedMarkerID !== -1) &&
                                <Link className="venue-list__item map" to={`/betriebe/${getEventById(selectedMarkerID, events)?.venue_slug}`}>
                                    <h3 className="venue-list__item-name">{getEventById(selectedMarkerID, events)?.venue_title}</h3>
                                    <p className="venue-list__item-hours">{getEventById(selectedMarkerID, events)?.description_1}</p>
                                    <p className="venue-list__item-hours">{getEventById(selectedMarkerID, events)?.description_2}</p>
                                </Link>
                                }
                            </div>
                        </Slider>
                    </div>
            </ion-page>
            ) : (
                <ion-page class="sg-body">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>Loading....</ion-title>
                        </ion-toolbar>
                    </ion-header>
                        <div className="sg-spinner"></div>
                </ion-page>
            )
        )
    )
}

export default pageTransition(WeeklyEvent);