import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import pageTransition from "../components/pageTransition";
import { useLocation } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";

const DataPrivacy: React.FC = () => {
    const { t } = useTranslation();

    // Load settings
    const [language] = useState(localStorage.getItem('i18nextLng') || 'de');

    const location = useLocation();
    const { trackPageView } = useMatomo();
  
    useEffect(() => {
      trackPageView({
        href: window.location.href, // URL der aktuellen Seite
      });
    }, [location]);

    return (
        <ion-page class="sg-body generic">
            <MetaHelmet 
                title={t('data-privacy')} 
            />
            <div
                className="logo-container"
                style={{ backgroundImage: `url("/icons/smartGastro-rund-white.svg")` }}
            >
                <h1
                    style={{ width: '300px' }}
                    className="start-title">{t('data-privacy')}</h1>
            </div>
            {(language === 'de') &&
                <div className="generic-page german">
                    Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten
                    daher
                    ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen
                    Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung.
                    <br />
                    <br />
                    Welche Daten werden bei uns gespeichert?
                    <br />
                    <br />
                    Besucher:
                    <br />
                    <br />
                    <br />
                    Wenn Sie bei uns nicht angemeldet, oder registriert sind, dann können Sie unsere Webseite als
                    Besucher
                    nutzen. Als Besucher unserer Seite speichern wir von Ihnen:
                    <br />
                    <br />
                    die komplette Internetadresse (URL) der aufgerufenen Webseite
                    Browser und Browserversion (z. B. Chrome 87)
                    das verwendete Betriebssystem (z. B. Windows 10)
                    die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B.
                    https://www.beispielquellsite.de/vondabinichgekommen.html/)
                    den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird
                    Datum und Uhrzeit
                    in Dateien, den sogenannten Webserver-Logfiles
                    Daten von gastronomischen Betrieben:
                    <br />
                    <br />
                    Wir haben in unserer Datenbank wichtige Informationen zu gastronomischen Betrieben gespeichert.
                    Dazu
                    gehören:
                    <br />
                    <br />
                    Bezeichnung
                    Öffnungszeiten
                    Leistungen (Angebot der Betriebe)
                    Geografische Daten (Adresse und Koordinaten)
                    Kontaktdaten (E-Mail Adresse und Telefonnummer)
                    Links zu den jeweiligen Webseiten und den dazugehörigen Speisekarten
                    Bilder
                    Die Information zu den angezeigten Betrieben setzen sich aus verschiedenen Quellen zusammen.
                    Diese
                    bestehen aus Webseiten der Betriebe, direkte Auskünfte bei den Betrieben, Erfahrungen der
                    Websitebetreiber und Dritten sowie aus öffentlichen Publikationen (Zeitungsartikeln,
                    Social-Media
                    Beiträgen etc.).
                    <br />
                    <br />

                    Wir erhalten und verarbeiten diese Daten aufgrund von berechtigtem Interesse gegenüber den
                    Betrieben
                    oder denen eines Dritten. Das berechtigte Interesse beruht auf der Annahme, dass die
                    Informationen auf
                    der Webseite einen Vorteil für die Nutzer sowie für die gelisteten Betriebe bieten.
                    <br />
                    <br />

                    Allgemein besteht das Recht auf Berichtigung jeglicher Informationen und falls nötig eine
                    Löschung der
                    Daten. Wir bitten sie eine Berichtigung oder Löschung der zu ihrem Betrieb angehörigen Daten uns
                    in
                    schriftlicher Form an info@smartgastro.at zu schicken.
                    <br />
                    <br />

                    Cookies
                    Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die
                    mit Hilfe
                    des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an.
                    <br />
                    <br />

                    Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf
                    Ihrem
                    Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten
                    Besuch
                    wiederzuerkennen. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website
                    eingeschränkt sein.
                    <br />
                    <br />

                    Diese Website verwendet Borlabs Cookie, das ein technisch notwendiges Cookie (borlabs-cookie)
                    setzt, um
                    Ihre Cookie-Einwilligungen zu speichern. Borlabs Cookie verarbeitet keinerlei personenbezogenen
                    Daten.Im
                    Cookie borlabs-cookie werden Ihre Einwilligungen gespeichert, die Sie beim Betreten der Website
                    gegeben
                    haben.
                    <br />
                    <br />

                    Möchten Sie diese Einwilligungen widerrufen, können sie nachfolgend ihre Cookie Einstellungen
                    ändern:
                    <br />
                    <br />

                    Persönliche Cookie Einstellungen
                    Webhosting
                    Bei dem Besuch auf unserer Website werden gewisse essentielle Informationen erstellt und auf dem
                    Webhosting Server gespeichert. Wir verwenden den Webhosting Anbieter: Hetzner Online GmbH
                    <br />
                    <br />

                    Mehr Informationen dazu wie Ihre Daten bei diesem Anbieter gespeichert werden finden Sie hier:
                    https://www.hetzner.com/legal/privacy-policy
                    <br />
                    <br />

                    Die Daten dieser Webseite und Matomo werden in Deutschland gehostet. Die Daten verlassen nie die
                    EU.
                    <br />
                    <br />

                    Newsletter
                    Sie haben die Möglichkeit, über unsere Website unseren Newsletter zu abonnieren. Hierfür
                    benötigen wir
                    Ihre E-Mail-Adresse und ihre Erklärung, dass Sie mit den Datenschutzbestimmungen unserer Seite
                    einverstanden sind. Wir verwenden für unseren Newsletter den Dienst Mailchimp, welcher unter der
                    Firma
                    The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308 USA
                    läuft.
                    <br />
                    <br />

                    Hier finden Sie die Datenschutzerklärung von Mail-Chimp:
                    https://www.intuit.com/privacy/statement/
                    <br />
                    <br />

                    Youtube
                    Unsere Website nutzt allenfalls Plugins der von Google betriebenen Seite YouTube. Betreiber der
                    Seiten
                    ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie eine unserer mit einem
                    YouTube-Plugin ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern von YouTube
                    hergestellt, sobald Sie die Wiedergabe eines Videos starten. Dabei wird dem Youtube-Server
                    mitgeteilt,
                    welche unserer Seiten Sie besucht haben.
                    <br />
                    <br />

                    Die Datenschutzerklärung von Youtube finden sie unter: https://policies.google.com/privacy
                    <br />
                    <br />

                    Google Maps
                    Diese Webseite verwendet das Produkt Google Maps von Google LLC zur Darstellung des Standortes
                    der
                    Betriebe. Durch Nutzung dieser Webseite erklären Sie sich mit der Erfassung, Bearbeitung sowie
                    Nutzung
                    der automatisiert erhobenen Daten durch Google LLC, deren Vertreter sowie Dritter einverstanden.
                    <br />
                    <br />

                    Die Datenschutzerklärung von Google finden sie unter: https://policies.google.com/privacy
                    <br />
                    <br />

                    Matomo
                    Diese Webseite verwendet Matomo, eine Open Source, selbstgehostete Software um anonyme
                    Nutzungsdaten für
                    diese Webseite zu sammeln.
                    <br />
                    <br />

                    Die Daten zum Verhalten der Besucher werden gesammelt um eventuelle Probleme wie nicht gefundene
                    Seiten,
                    Suchmaschinenprobleme oder unbeliebte Seiten herauszufinden. Sobald die Daten (Anzahl der
                    Besucher die
                    Fehlerseiten oder nur eine Seite sehen, usw.) verarbeitet werden, erzeugt Matomo Berichte für
                    die
                    Webseitenbetreiber, damit diese darauf reagieren können. (Layoutveränderungen, neue Inhalte,
                    usw.)
                    <br />
                    <br />

                    Matomo verarbeitet die folgenden Daten:
                    <br />
                    <br />

                    Cookies
                    Anonymisierte IP-Adressen indem die letzten 2 bytes entfernt werden (also 198.51.0.0 anstatt
                    198.51.100.54)
                    Pseudoanonymisierter Standort (basierend auf der anonymisierten IP-Adresse)
                    Datum und Uhrzeit
                    Titel der aufgerufenen Seite
                    URL der aufgerufenen Seite
                    URL der vorhergehenden Seite (sofern diese das erlaubt)
                    Bildschirmauflösung
                    Lokale Zeit
                    Dateien die angeklickt und heruntergeladen wurden
                    Externe Links
                    Dauer des Seitenaufbaus
                    Land, Region, Stadt (mit niedriger Genauigkeit aufgrund von IP-Adresse)
                    Hauptsprache des Browsers
                    User Agent des Browsers
                    Interaktionen mit Formularen (aber nicht deren Inhalt)
                    Die Datenverarbeitung basiert auf dem Prinzip des legitimen Interesses.
                    <br />
                    <br />

                    Verarbeiten der Daten hilft uns herauszufinden, was auf unserer Seite funktioniert und was
                    nicht. Zum
                    Beispiel finden wir damit heraus, ob die Inhalte gut ankommen oder wie wir die Struktur der
                    Webseite
                    verbessern können. Unser Team profitiert davon und kann darauf reagieren. Aufgrund der
                    Datenverarbeitung
                    profitieren Sie somit von einer Webseite, die laufend besser wird.
                    <br />
                    <br />

                    Ohne den Daten könnten wir den Service nicht bieten. Ihre Daten werden ausschließlich zum
                    Verbessern der
                    Webseitennutzung verwendet.
                    <br />
                    <br />

                    Sie haben die Möglichkeit zu verhindern, dass von Ihnen hier getätigte Aktionen analysiert und
                    verknüpft
                    werden. Dies wird Ihre Privatsphäre schützen, aber wird auch den Besitzer daran hindern, aus
                    Ihren
                    Aktionen zu lernen und die Bedienbarkeit für Sie und andere Benutzer zu verbessern.
                    <br />
                    <br />

                    Ihr Besuch dieser Webseite wird aktuell von der Matomo Webanalyse erfasst. Diese Checkbox
                    abwählen für
                    Opt-Out.
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            }
            {(language !== 'de') &&
                <div className="generic-page english">
                    The protection of your personal data is of particular concern to us. We therefore process your data exclusively based on legal regulations (GDPR, TKG 2003). In this privacy policy, we inform you about the most important aspects of data processing.
                    <br />
                    <br />
                    What data is stored with us?
                    <br />
                    <br />
                    Visitors:
                    <br />
                    <br />
                    <br />
                    If you are not registered or logged in, you can use our website as a visitor. As a visitor to our site, we store the following information:
                    <br />
                    <br />
                    - The complete internet address (URL) of the accessed website
                    - Browser and browser version (e.g., Chrome 87)
                    - The operating system used (e.g., Windows 10)
                    - The address (URL) of the previously visited site (Referrer URL) (e.g., https://www.example-source-site.com/whereicamefrom.html/)
                    - The hostname and IP address of the device from which access is made
                    - Date and time
                    - In files, so-called web server log files
                    <br />
                    <br />
                    Data of gastronomic businesses:
                    <br />
                    <br />
                    We store important information about gastronomic businesses in our database. This includes:
                    <br />
                    <br />
                    - Name
                    - Opening hours
                    - Services (offerings of the businesses)
                    - Geographical data (address and coordinates)
                    - Contact details (email address and phone number)
                    - Links to the respective websites and associated menus
                    - Images
                    The information displayed about the businesses is compiled from various sources. These include the websites of the businesses, direct information from the businesses, experiences of the website operators and third parties, as well as public publications (newspaper articles, social media posts, etc.).
                    <br />
                    <br />

                    We receive and process this data based on legitimate interest towards the businesses or that of a third party. The legitimate interest is based on the assumption that the information on the website provides a benefit to both users and the listed businesses.
                    <br />
                    <br />

                    In general, there is the right to correct any information and, if necessary, to delete the data. We ask you to send a correction or deletion request of your business-related data to us in written form at info@smartgastro.at.
                    <br />
                    <br />

                    Cookies
                    Our website uses so-called cookies. These are small text files that are stored on your device using the browser. They do not cause any harm.
                    <br />
                    <br />

                    We use cookies to make our offer user-friendly. Some cookies remain stored on your device until you delete them. They allow us to recognize your browser the next time you visit. Disabling cookies may limit the functionality of our website.
                    <br />
                    <br />

                    This website uses Borlabs Cookie, which sets a technically necessary cookie (borlabs-cookie) to store your cookie consents. Borlabs Cookie does not process any personal data. The borlabs-cookie stores your consents given when entering the website.
                    <br />
                    <br />

                    If you wish to revoke these consents, you can change your cookie settings below:
                    <br />
                    <br />

                    Personal cookie settings
                    Web hosting
                    Certain essential information is created and stored on the web hosting server when visiting our website. We use the web hosting provider: Hetzner Online GmbH
                    <br />
                    <br />

                    For more information on how your data is stored by this provider, see here: https://www.hetzner.com/legal/privacy-policy
                    <br />
                    <br />

                    The data of this website and Matomo are hosted in Germany. The data never leaves the EU.
                    <br />
                    <br />

                    Newsletter
                    You have the option to subscribe to our newsletter through our website. For this, we need your email address and your declaration that you agree with our site's privacy policy. We use the service Mailchimp for our newsletter, operated by The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308 USA.
                    <br />
                    <br />

                    You can find Mailchimp's privacy policy here: https://www.intuit.com/privacy/statement/
                    <br />
                    <br />

                    YouTube
                    Our website may use plugins from the YouTube site operated by Google. The operator of the site is YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. When you visit one of our pages equipped with a YouTube plugin, a connection to YouTube's servers is established as soon as you start playing a video. The YouTube server is informed about which of our pages you have visited.
                    <br />
                    <br />

                    You can find YouTube's privacy policy here: https://policies.google.com/privacy
                    <br />
                    <br />

                    Google Maps
                    This website uses the Google Maps product by Google LLC to display the location of businesses. By using this website, you agree to the collection, processing, and use of the automatically collected data by Google LLC, its representatives, and third parties.
                    <br />
                    <br />

                    You can find Google's privacy policy here: https://policies.google.com/privacy
                    <br />
                    <br />

                    Matomo
                    This website uses Matomo, an open-source, self-hosted software to collect anonymous usage data for this website.
                    <br />
                    <br />

                    The data on visitor behavior is collected to identify potential issues such as not found pages, search engine problems, or unpopular pages. Once the data (number of visitors viewing error pages or only one page, etc.) is processed, Matomo generates reports for the website operators to react (layout changes, new content, etc.).
                    <br />
                    <br />

                    Matomo processes the following data:
                    <br />
                    <br />

                    - Cookies
                    - Anonymized IP addresses by removing the last 2 bytes (so 198.51.0.0 instead of 198.51.100.54)
                    - Pseudonymized location (based on the anonymized IP address)
                    - Date and time
                    - Title of the accessed page
                    - URL of the accessed page
                    - URL of the previous page (if allowed)
                    - Screen resolution
                    - Local time
                    - Files clicked and downloaded
                    - External links
                    - Duration of page load
                    - Country, region, city (with low accuracy based on IP address)
                    - Main language of the browser
                    - User agent of the browser
                    - Interactions with forms (but not their content)
                    <br />
                    <br />

                    Data processing is based on the principle of legitimate interest.
                    <br />
                    <br />

                    Processing the data helps us identify what works on our site and what doesn’t. For example, we can see if the content is well received or how we can improve the website's structure. Our team benefits from this and can respond accordingly. As a result, you benefit from a continuously improving website.
                    <br />
                    <br />

                    Without the data, we couldn’t offer the service. Your data is used exclusively to improve the use of the website.
                    <br />
                    <br />

                    You have the option to prevent the actions you take here from being analyzed and linked. This will protect your privacy, but it will also prevent the owner from learning from your actions and improving usability for you and other users.
                    <br />
                    <br />

                    Your visit to this website is currently being tracked by Matomo Web Analytics. Uncheck this checkbox for opt-out.
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            }
        </ion-page>
    );
}

export default pageTransition(DataPrivacy);
