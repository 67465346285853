import { Link } from 'react-router-dom';

export default function VenueCard(props: { name: string, slug: string, status: string, additionalInformation: string }) {
    const { name, slug, status, additionalInformation } = props;

    return (
        <Link className="venue-list__item" to={`/betriebe/${slug}`}>
            <h3 className="venue-list__item-name">
                {name}</h3>
            <p className="venue-list__item-hours">{status}</p>
            <p className="venue-list__item-hours">{additionalInformation}</p>
        </Link>
    )
}